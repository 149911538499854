import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Router } from '@angular/router';
import * as moment from "moment";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-listar-facturacao',
  templateUrl: './listar-facturacao.component.html',
  styleUrls: ['./listar-facturacao.component.css']
})
export class ListarFacturacaoComponent implements OnInit {

  private facturas: any = [];
  CurrentDate = new Date();
  mes = moment(this.CurrentDate).format("MM")

  private motivo = '';
  private faturaId = '';

  private factura = {
    factura_id: null,
    factura_sigla: null,
    status_reason: null,
  };

  private dashboard = {
    countRecibos: null,
    countFacturas: null,
    countFacturasVencidas: null,
    countFacturasContaCorrente: null
  }

  constructor(private http: HttpService, private configService: ConfigService, private _route: Router, public toasterService: ToastrService) {

  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.dashboardFacturacao();
  }


  /**
  * @name "Breve estatistica de facturação"
  * @descriptio "Esta Função permite Estatistica todas facturações"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  private dashboardFacturacao() {
    this.http.call_get('factura/dashboard', null).subscribe(
      response => {
        this.dashboard.countRecibos = Object(response).data.countRecibos
        this.dashboard.countFacturas = Object(response).data.countFacturas
        this.dashboard.countFacturasVencidas = Object(response).data.countFacturasVencidas
        this.dashboard.countFacturasContaCorrente = Object(response).data.countFacturasContaCorrente
      }
    );
  }

  private reimprimirFatura(fatura) {
      this.motivo = '';
      this.faturaId = fatura.factura_id;
  }

  private confirmarReimprimirFatura() {
      if (this.motivo) {
        this.http.__call('factura/reimprimir', { faturaId: this.faturaId, motivo: this.motivo }).subscribe(
            response => {
                // $('#reimprimirModal').modal('hide');
            }
        );
      } else {
        this.toasterService.error('Dados inválidos', 'Motivo é obrigatório');
      }


  }

  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarFacturacao() {

    this.configService.loaddinStarter('start');
    this.http.__call('factura/list', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.facturas = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  //--------------------------------------------------------------------------

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarFacturacao();
  }
  //--------------------------------------------------------------------------


  private getFactura(factura: any) {
    this.factura = factura;
  } 

  /**
   * @name "Anualar Factura"
   * @descriptio "Esta Função permite fazer a anulação de uma deteminada factura"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private anularFactura(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (e.target.elements[0].value == "" || e.target.elements[2].value == "") {
      this.configService.showAlert('É obrigatório fornecer o nº da factura e o motivo', 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.factura.status_reason = e.target.elements[2].value;
      this.http.__call('factura/anular/' + this.factura.factura_id, this.factura).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarFacturacao();
            this.dashboardFacturacao();
            this.configService.clearFormInputs(e);
          }
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }


  goToPageCreateNotaCredito(factura: any) {
    this._route.navigate(['/facturacao/emitir-nota-de-credito', factura.factura_id]);
  }

  private imprimirFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via");
  }

  private DownloadFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via", "download");  
  }
  calcularPercentual(f: any) {
    var calc = f.total - f.valor_aberto;
    return (f.pago === 0 && f.status === 'N' && f.valor_aberto == null ? 0 : (calc * 100) / f.total) //(f.total === f.valor_aberto ? 0 : f.pago == 1 ? 100 : (calc * 100) / f.total);
  }
 
  
}
