import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-armazem',
  templateUrl: './armazem.component.html',
  styleUrls: ['./armazem.component.css']
})
export class ArmazemComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc")  orderAscOrDesc;

  private armazem = {
    id: null,
    nome: null,
    descricao: null
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "nome"
  };

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pesquisarArmazem(e) {
    this.pagination.page = 1;
    this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }

  private listArmazem(start, end, search, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = (search == "" ? "" : search);
    this.pagination.order = orderBy;

    this.http.__call('armazem/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }
  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (e.target.elements[0].value == "") {

      this.configService.showAlert('É obrigatório fornecer o nome', 'alert-danger', true);

    } else {
      this.armazem.nome = e.target.elements[0].value;
      this.armazem.descricao = e.target.elements[1].value;
      
      this.http.__call('armazem/register', this.armazem).subscribe(
        data => {
          if(Object(data).code == 500){
            this.configService.showAlert(Object(data).message, 'alert-danger', true); 
          }else{
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
          this.configService.clearFormInputs(e);
          }
          
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private alert = {
    message: null,
    show: false,
    class: null
  }

  private alerts(message, classs) {
    this.alert.message = message;
    this.alert.class = classs;
    this.alert.show = true;
    setTimeout(() => {
      this.alert.show = false;
    }, 5000);
  }


  private refreshDataArmazem(id, nome, descricao) {
    this.armazem.id = id;
    this.armazem.nome = nome;
    this.armazem.descricao = descricao;
  }

  private editar(e) {
    e.preventDefault();

    if (e.target.elements[0].value == "") {
      this.alerts('É obrigatório fornecer o nome', "alert-danger");
    } else {
      this.configService.loaddinStarter('start');

      this.armazem.nome = e.target.elements[0].value;
      this.armazem.descricao = e.target.elements[1].value;
       

      this.http.__call('armazem/update/' + this.armazem.id, this.armazem).subscribe(
        response => {

          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(response).message, 'alert-sucess', true);

          this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);


        }
      );
    }
  }
 
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listArmazem(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }


}
