import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-tipo-identidade',
  templateUrl: './tipo-identidade.component.html',
  styleUrls: ['./tipo-identidade.component.css']
})
export class TipoIdentidadeComponent implements OnInit {
  
  private tipoIdentidade = {
    id: null,
    tipoIdentificacao: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private tiposIdentificacao() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipos/identificacao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.tiposIdentificacao();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoIdentidade.tipoIdentificacao == null || this.tipoIdentidade.tipoIdentificacao == "") {
      this.configService.showAlert("Descrição é Obrigatório", 'alert-danger', true);
    } else {
    this.http.__call('tipo/identificacao/create', this.tipoIdentidade).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.tiposIdentificacao()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  }
      ;
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
  }

  private refresh(id, tipoIdentificacao) {

    this.tipoIdentidade.id = id;
    this.tipoIdentidade.tipoIdentificacao = tipoIdentificacao;

  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoIdentidade.tipoIdentificacao == "" || this.tipoIdentidade.tipoIdentificacao == null) {
      this.configService.showAlert("O campo Descrição é obrigatório", 'alert-danger', true);
    } else {
      this.http.__call('tipo/identificacao/update/' + this.tipoIdentidade.id, this.tipoIdentidade).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.tiposIdentificacao();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.tipoIdentidade = {
      id: null,
      tipoIdentificacao: null
    }
  }

}
