import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ReportImpostoService } from 'src/app/components/Relatorios/report-imposto.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-imposto',
  templateUrl: './imposto.component.html',
  styleUrls: ['./imposto.component.css']
})
export class ImpostoComponent implements OnInit {

  public imposto: any;
  private loading: boolean = false;
  private dados_empresa: any = [];

  private items: any = [];

  private exemptionReasons: any = [
    {
      slug: 'M01',
      description: 'Artigo 16.º n.º 6 do CIVA'
    },
    {
      slug: 'M02',
      description: 'Artigo 6.º do Decreto-Lei n.º 198/90, de 19 de Junho'
    },
    {
      slug: 'M03',
      description: 'Exigibilidade de caixa'
    },
    {
      slug: 'M04',
      description: 'Isento Artigo 13.º do CIVA'
    },
    {
      slug: 'M05',
      description: 'Isento Artigo 14.º do CIVA'
    },
    {
      slug: 'M06',
      description: 'Isento Artigo 15.º do CIVA'
    },
    {
      slug: 'M07',
      description: 'Isento Artigo 9.º do CIVA'
    },
    {
      slug: 'M08',
      description: 'IVA – Autoliquidação'
    },
    {
      slug: 'M09',
      description: 'IVA – Não confere direito a dedução'
    },
    {
      slug: 'M10',
      description: 'IVA – Regime de isenção'
    },
    {
      slug: 'M11',
      description: 'Regime particular do tabaco'
    },
    {
      slug: 'M12',
      description: 'Regime da margem de lucro – Agências de viagens'
    },
    {
      slug: 'M13',
      description: 'Regime da margem de lucro – Bens em segunda mão'
    },
    {
      slug: 'M14',
      description: 'Regime da margem de lucro – Objectos de arte'
    },
    {
      slug: 'M15',
      description: 'Regime da margem de lucro – Objectos de colecção e antiguidades'
    },
    {
      slug: 'M16',
      description: 'Isento Artigo 14.º do RITI'
    },
    {
      slug: 'M20',
      description: 'IVA – Regime forfetário'
    },
    {
      slug: 'M99',
      description: 'Não sujeito; não tributado'
    }
  ];

  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private ReportImposto:ReportImpostoService) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.user_empresa()
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_impostoss-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    this.ReportImposto.relatorioImpostos(this.items, 'save',this.dados_empresa);
  }

  imprimirPDF(): void {
    this.ReportImposto.relatorioImpostos(this.items, 'imprimir',this.dados_empresa);
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarImpostos();
  }

  getExemptionReasonDescription(code: string) {
    const exemptionReason = this.exemptionReasons.find((eR) => eR.slug === code);

    if (exemptionReason) {
        return exemptionReason.description;
    }
  }

  /**
   * @name "Listar Impostos"
   * @descriptio "Esta Função permite Listar todos impostos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private listarImpostos() {

    this.loading = true
    this.http.__call('imposto/listar', this.http.filters).subscribe(
      response => {

        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false;

      }
    );
  }

  private setDataImposto(imposto) {
    this.imposto = imposto
  }

  public user_empresa() {
    this.http.call_get('empresa/empresa-user/',null).subscribe(
      response => {
        this.dados_empresa = Object(response).data;
      }
    );
  }
}

