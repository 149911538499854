import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';

@Component({
  selector: 'app-criar-artigo',
  templateUrl: './criar-artigo.component.html',
  styleUrls: ['./criar-artigo.component.css']
})
export class CriarArtigoComponent implements OnInit {

  private artigo ={
    nome: null,
    valor: null,
    quantidate: null,
    barcode: null

  }
  constructor(private http: HttpService, private auth: AuthService) { }

  ngOnInit() {
  }

  private register(e){
    e.preventDefault();
    
    if (e.target.elements[0].value && e.target.elements[1].value && e.target.elements[2].value && e.target.elements[3].value) {

        this.artigo.nome = e.target.elements[0].value;
        this.artigo.valor = e.target.elements[1].value;
        this.artigo.quantidate = e.target.elements[2].value;
        this.artigo.barcode = e.target.elements[3].value;
        this.http.__call('artigo/create', this.artigo).subscribe(
          data =>{
            console.log(data)
          }
        );

    }
  }
}
