import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc")  orderAscOrDesc;

  private empresa = {
    id: null,
    companyName: null,
    telefone: null,
    taxRegistrationNumber: null,
    city: null,
    province: null,
    addressDetail: null,
    email: null,
    active_tfa: 0,
    logotipo:null,
    height:null,
    width:null,
    wholesaler: null,
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "companyName"
  };

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
     this.empresaUser();
  }

  private pesquisarEmpresa(e) {
    this.pagination.page = 1;
   // this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }
/*
  private listEmpresas(start, end, search, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = (search == "" ? "" : search);
    this.pagination.order = orderBy;

    this.http.__call('empresa/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }
*/ 
  private empresaUser() {

    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(

      response => {
        
        this.empresa.id = Object(response).data[0].id
        this.empresa.companyName = Object(response).data[0].companyName
        this.empresa.taxRegistrationNumber = Object(response).data[0].taxRegistrationNumber
        this.empresa.telefone = Object(response).data[0].telefone
        this.empresa.addressDetail = Object(response).data[0].addressDetail
        this.empresa.city = Object(response).data[0].city
        this.empresa.province = Object(response).data[0].province
        this.empresa.active_tfa = Object(response).data[0].active_tfa
        this.empresa.logotipo = Object(response).data[0].logotipo
        this.empresa.email = Object(response).data[0].email
        this.empresa.height = Object(response).data[0].height
        this.empresa.width = Object(response).data[0].width
        this.empresa.wholesaler = Object(response).data[0].wholesaler
        // console.log(Object(response).data[0])
        this.configService.loaddinStarter('stop');

      }
    );
  }
  
  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (e.target.elements[0].value == "" || e.target.elements[1].value == "" || e.target.elements[2].value == "" || e.target.elements[3].value == "") {

      this.configService.showAlert('É obrigatório fornecer o nome, telefone, contribuente e morada do cliente', 'alert-danger', true);

    } else {
      this.empresa.companyName = e.target.elements[0].value;
      this.empresa.telefone = e.target.elements[1].value;
      this.empresa.taxRegistrationNumber = e.target.elements[2].value;
      this.empresa.city = e.target.elements[3].value;
      this.empresa.province = e.target.elements[4].value;
      this.empresa.addressDetail = e.target.elements[5].value;
      this.empresa.width = e.target.elements[6].value;
      this.empresa.height = e.target.elements[7].value;
      this.empresa.wholesaler = e.target.elements[8].value;
      this.http.__call('empresa/register', this.empresa).subscribe(
        data => {
          if(Object(data).code == 500){
            this.configService.showAlert(Object(data).message, 'alert-danger', true); 
          }else{
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
          this.configService.clearFormInputs(e);
          }
          
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private alert = {
    message: null,
    show: false,
    class: null
  }

  private alerts(message, classs) {
    this.alert.message = message;
    this.alert.class = classs;
    this.alert.show = true;
    setTimeout(() => {
      this.alert.show = false;
    }, 5000);
  }


  private refreshDataEmpresa(id, companyName, taxRegistrationNumber, telefone, city, province, addressDetail, active_tfa, email, height, width, wholesaler) {
    this.empresa.id = id;
    this.empresa.companyName = companyName;
    this.empresa.taxRegistrationNumber = taxRegistrationNumber;
    this.empresa.telefone = telefone;
    this.empresa.city = city;
    this.empresa.province = province;
    this.empresa.addressDetail = addressDetail;
    this.empresa.active_tfa = active_tfa
    this.empresa.email = email
    this.empresa.height = height
    this.empresa.width = width
    this.empresa.wholesaler = wholesaler
  }

  private editar(e) {
    e.preventDefault();

    if (this.empresa.companyName == null || this.empresa.telefone  == null || this.empresa.taxRegistrationNumber == null || this.empresa.city == null || this.empresa.province == null || this.empresa.addressDetail == null || this.empresa.addressDetail == null) {
      this.configService.showAlert('É obrigatório fornecer o Nome, Telefone, NIF, Cidade, Distrito, Endereço', 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');

      
      console.log(this.empresa);
      this.http.__call('empresa/update/' + this.empresa.id, this.empresa).subscribe(
        response => {

          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          
          //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
          this.empresaUser();

        }
      );
    }
  }
 
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }


}
