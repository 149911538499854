import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportImpostoService {

  constructor(private configService: ConfigService) { }

  public relatorioImpostos(relatorio: any,  p = 'print', empresa:any) {


    // var imgData = this.configService.logotipoBase64;
 
     var doc = new jsPDF('l', '', 'a4')
    // doc.addImage(imgData, 'JPEG', 130, 17, 24, 20)
 
    //dados da empresa
    
   for(var i= 0; i< empresa.length; i++){
   doc.text(150, 60,empresa[i].companyName ,'center')
   doc.addImage(empresa[i].logotipo, 'JPEG',130, 17,empresa[i].width,empresa[i].height)
   }
     doc.setFontType('bold')
     doc.setFontSize(15)
    // doc.text(145, 60, 'Relatório', 'center')
     doc.setFontSize(15)
     doc.text(150, 70, 'Listagem de Impostos', 'center')
 
     doc.setDrawColor(150);
     doc.setFillColor(255);
     doc.setFontSize(7);
     doc.setTextColor(0);
 
     var posY = 0;
     doc.rect(5, 80 + posY, 18, 6, 'B'); // filled red square 
     doc.rect(23, 80 + posY, 27, 6, 'B'); // filled red square 
     doc.rect(50, 80 + posY, 140, 6, 'B'); // filled red square 
     doc.rect(190, 80 + posY, 20, 6, 'B'); // filled red square
     doc.rect(210, 80 + posY, 25, 6, 'B'); // filled red square
     doc.rect(235, 80 + posY, 30, 6, 'B'); // filled red square
     doc.rect(265, 80 + posY, 25, 6, 'B'); // filled red square
 
     doc.setFontType("bold");
     doc.text('Nº', 15, 84 + posY, 'center');
     doc.text('Código do Imposto', 36.5, 84 + posY, 'center');
     doc.text('Descrição', 120, 84 + posY, 'center');
     doc.text('Valor(%)', 201, 84 + posY, 'center');
     doc.text('Data Entrada', 223, 84 + posY, 'center');
     doc.text('Data Actualização',250, 84 + posY, 'center');
     doc.text('Activo', 278, 84 + posY, 'center');
 
     posY = 5;
     var totalva = 0
     var totalvf = 0
     var totalvv = 0
     var page = 0
     for (var i = 0; i < relatorio.length; i++) {
      let N_ordem = 1;
      N_ordem = N_ordem + i;
       doc.setDrawColor(150);
       doc.setFillColor(255);
       doc.rect(5, 80 + posY, 18, 6, 'B'); // filled red square 
       doc.rect(23, 80 + posY, 27, 6, 'B'); // filled red square 
       doc.rect(50, 80 + posY, 140, 6, 'B'); // filled red square 
       doc.rect(190, 80 + posY, 20, 6, 'B'); // filled red square
       doc.rect(210, 80 + posY, 25, 6, 'B'); // filled red square
       doc.rect(235, 80 + posY, 30, 6, 'B'); // filled red square
       doc.rect(265, 80 + posY, 25, 6, 'B'); // filled red square
       doc.setFontType("normal");
       doc.text('' + (N_ordem), 15, 84 + posY, 'center');
       doc.text('' + (relatorio[i].codigo == null? '': relatorio[i].codigo), 36.5, 84 + posY, 'center');
       doc.text('' + (relatorio[i].descricao == null? '': relatorio[i].descricao), 120, 84 + posY, 'center');
       doc.text('' + (relatorio[i].valor == null? '': relatorio[i].valor), 201, 84 + posY, 'center');
       doc.text('' + (relatorio[i].created_at == null? '': relatorio[i].created_at), 223, 84 + posY, 'center');
       doc.text('' + (relatorio[i].updated_at == null? '': relatorio[i].updated_at), 250, 84 + posY, 'center');
       doc.text('' + (relatorio[i].activo == 1? 'Activo': 'Inactivo'), 278, 84 + posY, 'center');
       posY += 6;
 
       if (page == 18 && i == 18) {
         posY += 20;
         page = 0;
       }
       if (page == 38 && i != 18) {
         posY += 20;
         page = 0;
       }
 
       if (doc.internal.pageSize.height < (posY + 35)) {
         posY = posY - 250;
         doc.addPage();
       }
       page++;
     }
     
 
 
 
     if (p === 'save') {
       doc.save();
     } else {
       doc.autoPrint();
       doc.output("dataurlnewwindow");
     }
 
 
   }
}
