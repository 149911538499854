import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-conta-corrente',
  templateUrl: './conta-corrente.component.html',
  styleUrls: ['./conta-corrente.component.css']
})

export class ContaCorrenteComponent implements OnInit {
 

  private cliente = {
    id: null,
    nome: null,
    telefone: null,
    morada: null,
    contribuente: null
  }

  private pagination = {
    total: null,
    perPage: 2,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "created_at"
  };

  private conta_corrente = {
    cliente:{
      id: null,
      nome: null,
      telefone: null,
      morada: null,
      contribuente: null
    },
    search:""

  }

  private clientes: any = [];
  private contas: any = {
    facturas: [],
    notaCreditos: [],
    recibos: []
  };

  private somaNotacredito = 0.0;
  private somaFactura = 0.0;
  private somaRecibo = 0.0;

  private validacao = false;

  public pieChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  public pieChartData = [120, 150, 180, 90];
  public pieChartType = 'pie';

  myChart = []
  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome');
  }

  private chartts() {

    this.myChart = new Chart('myChart', {
      type: 'bar',
      data: {
        labels: ['Fact pagas', 'Fact ñ pagas', 'Fact anuladas', 'Notas de crédito', 'Recibo', 'Total Geral'],
        datasets: [{
          label: '# Conta corrente por clientes ',
          data: [2200, 2829, 3000, 800, 1200, 9000],
          backgroundColor: [
            'rgb(40, 167, 69)',
            'rgb(0, 123, 255)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 206, 86, 1)',
            'rgb(222, 226, 230)'
          ],
          borderColor: [
            'rgb(40, 167, 69)',
            'rgb(0, 123, 255)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 206, 86, 1)',
            'rgb(222, 226, 230)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }
  private pesquisarCliente(e) {
    this.pagination.page = 1;
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome');
  }

  private ListarClientes(start, end, search, orderBy) {
    this.validacao = false;
    if (search != "") {
      this.configService.loaddinStarter('start');
      this.http.__call('cliente/search-cliente', { start: start, end: end, search: search, order: orderBy }).subscribe(
        response => {

          this.clientes = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );
    } else {
      this.clientes = [];
    }
  }

  private ListarClientess(start, end, search, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.order = orderBy;

    this.http.__call('cliente/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.clientes = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private refreshDataCliente(id, nome, contribuente, telefone, morada) {
    this.cliente.id = id;
    this.cliente.nome = nome;
    this.cliente.contribuente = contribuente;
    this.cliente.telefone = telefone;
    this.cliente.morada = morada;

  }

  private mostrarResultados() {
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome');

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome');

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome');
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome');
  }

  private deleteClienteNoSeleted(id) {
    
    this.clientes.forEach(element => {
      if (element.id == id) {
        this.conta_corrente.cliente.id = element.id;
        this.conta_corrente.cliente.nome = element.nome;
        this.conta_corrente.cliente.contribuente = element.contribuente;
        this.conta_corrente.cliente.telefone = element.telefone;
        this.conta_corrente.cliente.morada = element.morada;
        this.conta_corrente.search = element.nome;
      }
    });
    /*for (let i = 0; i < this.clientes.length; ++i) {
      if (this.clientes[i].id == id) {
      this.clientes.splice(i, 1);
      }
    }*/
    this.clientes = [];
  }

  private refreshClienteId(id) {
    this.cliente.id = id;
    this.deleteClienteNoSeleted(id);
    this.contaCorrentes();
  }


  private contaCorrentes() {
    this.configService.loaddinStarter('start');
    this.http.call_get('contaCorrente/contas/' + this.cliente.id, {}).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
          this.configService.loaddinStarter('stop');
        } else {
          this.validacao = true;
          this.contas = Object(res).data;
          console.log(this.contas);
          this.calcular();
          this.chartts();
          this.configService.loaddinStarter('stop');
        }
      }
    );
  }

  private calcular() {
    this.somaRecibo = 0.0;
    this.somaFactura = 0.0;
    this.somaNotacredito = 0.0;

    this.contas.recibos.forEach(element => {
      this.somaRecibo += element.total;
    });
    this.contas.facturas.forEach(element => {
      this.somaFactura += element.total;
    });
    this.contas.notaCreditos.forEach(element => {
      this.somaNotacredito += element.total;
    });
  }

}

