import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
import { ReportRecibosService } from 'src/app/components/Relatorios/report-recibos.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'consultar-recibo',
  templateUrl: './consultar-recibo.component.html',
  styleUrls: ['./consultar-recibo.component.css']
})
export class ConsultarReciboComponent implements OnInit {

  private search: string;

  loadingListarFacturacao = false

  public dados_empresa: any = [];
  private recibo = {
    recibo_id: null,
    recibo_sigla: null,
    status_reason: null,
    status_date: null,
    status: null
  }


  constructor(private http: HttpService, private configService: ConfigService, private PDFrecibo: ReciboService,private Reportrecibo: ReportRecibosService,  private excelService: ExcelService) {



  }


  ngOnInit() {
    this.getPageFilterData(1);
    this.user_empresa()
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "recibos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.Reportrecibo.relatorioRecibos(this.recibos, 'save', this.dados_empresa,file)
  }

  imprimirPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.Reportrecibo.relatorioRecibos(this.recibos, 'imprimir', this.dados_empresa,file)
  }



  private clientes: any = [];
  view_client = false;
  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private recibos: any = []
  private cliente: any = null;

  
  private setCliente(client: any) {
    this.cliente = client;
    this.search = client.nome
    this.view_client = false;
    this.http.__call('recibo/consultarRecibo/' + client.id, null).subscribe(
      response => {
        
        this.configService.loaddinStarter('stop');
        if (Object(response).code == 500) {
          this.recibos = []
          this.configService.showAlert(Object(response).message, 'alert-danger', true);
        }else{
          this.recibos = Object(response).data;
        }
      }
    );
  }

  

  private listarRecibo() {

   this.loadingListarFacturacao = true
    this.http.__call('recibo/listarRecibo', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.recibos = Object(response).data.data;
        this.loadingListarFacturacao = false
      }
    );
  }

  private btnImprimirRecibo(recibo: any) {
    this.PDFrecibo.imprimirRecibo(recibo.id, '2ª Via');
  }

  close() {
    this.search = ''
    this.recibos = []
  }


  /**
  * @name "Anualar Recibo"
  * @descriptio "Esta Função permite fazer a anulação de uma deteminada recibo"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  private anularRecibo(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.recibo.status_reason == null) {
      this.configService.showAlert('É obrigatório fornecer o nº da recibo e o motivo', 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('recibo/anular', this.recibo).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.setCliente(this.cliente);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarRecibo();
            this.recibo = {
              recibo_id: null,
              recibo_sigla: null,
              status_reason: null,
              status_date: null,
              status: null
            }
            
            this.responseCode = Object(res).code
          }

          
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }



  private responseCode: 0
  private setRecibo(recibo: any) {
    this.responseCode = 0
    this.recibo.recibo_id = recibo.id
    this.recibo.recibo_sigla = recibo.recibo_sigla
    this.recibo.status_reason = null
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarRecibo();
  }


  public user_empresa() {
    this.http.call_get('empresa/empresa-user/',null).subscribe(
      response => {
        this.dados_empresa = Object(response).data;
      }
    );
  }
}

