import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
//import { PedidoReportService } from '../report-at/pedido/pedido-report.service';
import { Response } from 'selenium-webdriver/http';

import { ReportClienteService } from 'src/app/components/Relatorios/report-cliente.service';
import { ExcelService } from 'src/app/services/excel.service'; 
import * as moment from 'moment';


@Component({
  selector: 'app-terceiro',
  templateUrl: './terceiro.component.html',
  styleUrls: ['./terceiro.component.css']
})
export class TerceiroComponent implements OnInit {

   
  childTitle:string = 'This text is passed to child';
  loading: boolean = false;
  submitted:boolean= false;
  paises:any = []
  
  private cliente = {
    id: null,
    nome: null,
    telefone: null,
    morada: null,
    contribuente: null,
    tipo_identidade_id: null,
    tipo_cliente_id: null,
    observacao: null,
    email: null,
    province: null,
    genero: null,
    direccao_id: null,
    gestor_conta: null
  } 
  
 


 
  private items: any = [];
  private dados_empresa: any = [];
  private provincias: any = []; 
  private tipos: any = []; 

  private tipoClientes: any = [];
  private tipoIdentidades: any = [];
   
  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private reportCliente: ReportClienteService) {

  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.user_empresa()
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_clientes-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m') 
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.reportCliente.relatorioClientes(this.items, 'save', this.dados_empresa,file);
  }

  imprimirPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.reportCliente.relatorioClientes(this.items,'imprimir',this.dados_empresa, file);
  }


  private listClientes() {
    this.loading= true
   // this.http.filters.filter = this.filter;
    this.http.__call('cliente/listar', this.http.filters).subscribe(
      response => {

        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data; 
        this.loading= false;

      }
    );
  }

  private listarTipoClientes() {
    this.configService.loaddinStarter('start');
    this.http.call_get('cliente/tipoCliente/listar', null).subscribe(
      response => {
        this.tipoClientes = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }
  
  private listarTipoIdentidades() {
    this.configService.loaddinStarter('start');
    this.http.call_get('cliente/tipoIdentidade/listar', null).subscribe(
      response => {
        this.tipoIdentidades = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }
  
  

  private clienteEdit: any;
  private setDataCliente(client: any){ 
    this.clienteEdit = client;
  }  
  
  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listClientes();
  }

 
  

  private getPaises() {
    this.http.call_get('select-box/listar/pais', null).subscribe(
      response => {
        this.paises = Object(response).data;
      }
    );
  }
  
  public user_empresa() {
    this.http.call_get('empresa/empresa-user/',null).subscribe(
      response => {
        this.dados_empresa = Object(response).data;
      }
    );
  }

}


