 
import { Component, NgModule, OnInit } from '@angular/core'; 
import { ConfigService } from 'src/app/providers/config/config.service'; 
@Component({
  selector: 'app-tarefa',
  templateUrl: './tarefa.component.html',
  styleUrls: ['./tarefa.component.css']
})
export class TarefaComponent implements OnInit {

 

  constructor(private configService: ConfigService) { }

  ngOnInit() { 
   
  }
 
}