import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private dash = {
    clienteCount: 0,
    facturaCount: 0,
    produtoCount: 0,
    documentoCount: 0,


    facturaSumTotalSemImpostoHoje: 0,
    facturaSumTotalSemImpostoMes: 0,
    facturaSumTotalSemImpostoOntem: 0,
    facturaSumTotalSemImpostoMesAnterior: 0,
    facturaSumTotalSemImpostoGeral: 0,

    facturaSumTotalComImpostoHoje: 0,
    facturaSumTotalComImpostoMes: 0,
    facturaSumTotalComImpostoOntem: 0,
    facturaSumTotalComImpostoMesAnterior: 0,
    facturaSumTotalComImpostoGeral: 0
  }
  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService) {
  }

  ngOnInit() {

    this.dashboard();

    /*.body.className = 'hold-transition sidebar-mini';

    if (this.auth.getLoading() == '1') {
      this.auth.setLoading(0);
      location.reload();
    }
*/
  }

  ngOnDestroy(): void {
    document.body.className = ' ';
  }

  /**
   * @name "Estatistica dashboard"
   * @descriptio "Estatistica dashboard"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private dashboard() {
    this.configService.loaddinStarter('start');
    this.http.call_get('dashboard/listar', null).subscribe(
      response => { 
        this.dash.clienteCount = Object(response).data.clienteCount;
        this.dash.produtoCount = Object(response).data.produtoCount;
        this.dash.facturaCount = Object(response).data.facturaCount;
        this.dash.documentoCount = Object(response).data.documentoCount;


        this.dash.facturaSumTotalSemImpostoHoje = Object(response).data.facturaSumTotalSemImpostoHoje;
        this.dash.facturaSumTotalSemImpostoOntem = Object(response).data.facturaSumTotalSemImpostoOntem;
        this.dash.facturaSumTotalSemImpostoMes = Object(response).data.facturaSumTotalSemImpostoMes;
        this.dash.facturaSumTotalSemImpostoMesAnterior = Object(response).data.facturaSumTotalSemImpostoMesAnterior;
        this.dash.facturaSumTotalSemImpostoGeral = Object(response).data.facturaSumTotalSemImpostoGeral;


        this.dash.facturaSumTotalComImpostoHoje = Object(response).data.facturaSumTotalComImpostoHoje;
        this.dash.facturaSumTotalComImpostoOntem = Object(response).data.facturaSumTotalComImpostoMes;
        this.dash.facturaSumTotalComImpostoMes = Object(response).data.facturaSumTotalComImpostoMes;
        this.dash.facturaSumTotalComImpostoMesAnterior = Object(response).data.facturaSumTotalComImpostoMesAnterior;
        this.dash.facturaSumTotalComImpostoGeral = Object(response).data.facturaSumTotalComImpostoGeral;
        this.configService.loaddinStarter('stop');
      }
    );
  }


}
