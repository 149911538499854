import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  //public screenWidth: any;
  
  title = 'smartInFront';
  ngOnInit() {
    //this.screenWidth = window.screen.width;
    //console.log(this.screenWidth);
  }
}

