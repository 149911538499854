 
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';


@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.css']
})
export class ContratoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;

  private cliente = {
    id: null,
    nome: null,
    telefone: null,
    morada: null,
    contribuente: null,
    observacao: null
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "created_at"
  };

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pesquisarCliente(e) {
    this.pagination.page = 1;
    this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }

  private listClientes(start, end, search, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.order = orderBy;

    this.http.__call('cliente/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (this.cliente.contribuente == null || this.cliente.nome == null) {

      this.configService.showAlert('É obrigatório fornecer o nome, telefone, contribuente e morada do cliente', 'alert-danger', true);

    } else {
      console.log(this.cliente)
      this.http.__call('cliente/register', this.cliente).subscribe(
        data => {
          if (Object(data).code == 500) {
            this.configService.showAlert(Object(data).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
            this.configService.clearFormInputs(e);
          }

        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private preenchAuto() {
    this.configService.loaddinStarter('start');

    if (this.cliente.contribuente.length > 8) {
      this.http.call_get('cliente/search-cliente-agt/' + this.cliente.contribuente, null).subscribe(
        response => {
          console.log(Object(response).data);
          if (Object(response).data != null) {
            this.cliente.nome = Object(response).data.nomeContribuinte;
            this.cliente.telefone = (Object(response).data.telefone == 'NA' ? '' : Object(response).data.telefone);
          } else {
            this.cliente.nome = null
            this.cliente.telefone = null
          }

          this.configService.loaddinStarter('stop');
        }
      );
    }

  }


  private refreshDataCliente(id, nome, contribuente, telefone, morada, observacao) {
    this.cliente.id = id;
    this.cliente.nome = nome;
    this.cliente.contribuente = contribuente;
    this.cliente.telefone = telefone;
    this.cliente.morada = morada;
    this.cliente.observacao = observacao

  }

  private editar(e) {
    e.preventDefault();
    if (e.target.elements[0].value == "" || e.target.elements[1].value == "" || e.target.elements[2].value == "" || e.target.elements[3].value == "") {
      this.configService.showAlert('É obrigatório fornecer o nome, telefone, contribuente e morada do cliente', "alert-danger", true);
    } else {
      this.configService.loaddinStarter('start');

      this.cliente.nome = e.target.elements[0].value;
      this.cliente.telefone = e.target.elements[1].value;
      this.cliente.contribuente = (e.target.elements[2].value == "" ? "999999999" : e.target.elements[2].value);
      this.cliente.morada = e.target.elements[3].value;
      this.cliente.observacao = e.target.elements[4].value;
      console.log(this.cliente);

      this.http.__call('cliente/update/' + this.cliente.id, this.cliente).subscribe(
        response => {

          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(response).message, 'alert-sucess', true);
          this.configService.clearFormInputs(e);
          this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

        }
      );
    }
  }
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listClientes(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }

}

