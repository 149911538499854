import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';


@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})

export class StockComponent implements OnInit {

  private stocks: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.stock();
  }

 private stock(){
   this.http.call_get('stockMovimento/stock', null).subscribe(
     response => {
       this.stocks = Object(response).data[0];
       console.log(Object(response).data[0]);
     })
 }
}
