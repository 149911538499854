
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
import _ from 'lodash'
// ES6 Modules or TypeScript 

@Component({
  selector: 'recibo',
  templateUrl: './recibo.component.html',
  styleUrls: ['./recibo.component.css']
})
export class ReciboComponent implements OnInit {

  private search: string;

  submitted = false;
  disable = false;

  loadingFacturacao = false;

  private recibo: any;


  reciboForm: FormGroup;

  private carregando = {
    serie: 'Selecione a serie',
    forma_pagamento: 'Selecione a forma de pagamento',
    banco: 'Selecione conta da bancaria'
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder, private PDFrecibo: ReciboService) {

    this.listarFormaPagamentos();
    this.configService.listarBancos();
    this.createForm();

  }


  ngOnInit() {
  }

  private

  createForm() {
    this.reciboForm = this.formBuilder.group({
      nome: [''],
      cliente_id: [null, Validators.required],
      serie_id: [null, Validators.required],

      forma_pagamento_id: [null],
      banco_id: [{ value: null, disabled: true }],
      referencia: [{ value: null, disabled: true }],
      data_pagamento: [{ value: null, disabled: true }],

      valor_recebido: [''],
      total_valor_recebido: ['', Validators.required],
      troco: [{ disabled: true }],
      total_pago: [{ disabled: true }],
      total_saldado: [{ disabled: true }],
      facturas: [{ value: null }],
      is_adiantamento: ['0'],
      adiantamentos: [{ value: null }],
      linha_pagamentos: [{ value: null }],
    });

    this.adiantamento = []
    this.listMovimento = []
    this.facturas = [];
    this.facturasSeleciondas = []
    this.linha_pagamentos = []
    this.view_facturas = false
    this.configService.loaddinStarter('stop');
    this.loadingFacturacao = false;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.reciboForm.controls;
  }

  onReset() {

    this.submitted = false;
    //this.reciboForm.reset();

    this.reciboForm.get('forma_pagamento_id').reset();
    this.reciboForm.get('valor_recebido').reset();
    this.reciboForm.get('data_pagamento').reset();
    this.reciboForm.get('referencia').reset();
    this.reciboForm.get('banco_id').reset();

    this.reciboForm.get('troco').reset();
    this.reciboForm.get('total_valor_recebido').reset();
    this.reciboForm.get('total_saldado').reset();
    this.reciboForm.get('is_adiantamento').reset();

    this.adiantamento = []
    this.listMovimento = []
    this.linha_pagamentos = []
    this.facturas = [];
    this.facturasSeleciondas = []
    this.view_facturas = false
    this.configService.loaddinStarter('stop');
    this.loadingFacturacao = false;

  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o reciboFormulário for inválido
    if (this.reciboForm.invalid) {
      return;
    }

    if (this.facturasSeleciondas.length == 0) {
      this.configService.showAlert('Nenhuma factura selecionada', "alert-danger", true);
      return;
    } else if (this.linha_pagamentos.length == 0) {
      this.configService.showAlert('Nenhuma forma de pagamento selecionada', "alert-danger", true);
      return;
    }

    var valorAberto = (_.sum(_.map(this.facturasSeleciondas, 'valor_aberto')));
    var valorEntrada = (_.sum(_.map(this.linha_pagamentos, 'valor_entrada')));

    if(+valorAberto.toFixed(2) != +valorEntrada.toFixed(2)) {
      this.configService.showAlert('O valor do recibo tem de ser pago pelo total do valor em aberto das facturas', "alert-danger", true);
      return;
    }

    this.loadingFacturacao = true
    this.reciboForm.patchValue({
      facturas: this.facturasSeleciondas,
      adiantamentos: this.adiantamento,
      linha_pagamentos: this.linha_pagamentos
    });
    //this.configService.loaddinStarter('start');
    const uri = 'recibo/gerarRecibo';
    this.createOrEdit(uri, this.reciboForm, true);

  }


  createOrEdit(uri: any, reciboFormulario: FormGroup, isCreate: boolean) {
    var nome: string;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, reciboFormulario.value).subscribe(
      res => {

        if (Object(res).code === 200) {
          this.submitted = false;
          if (isCreate) {
            this.printer_recibo = false;
            nome = reciboFormulario.value.nome
            this.recibo = Object(res).data.recibo.id;
            this.reciboForm.reset();
          /*   this.reciboForm.setValue(cliente_id).reset(); */
           /*  this.reciboForm.patchValue({
              cliente_id: Object(res).data.recibo.cliente_id,
              nome: nome
            });
            this.facturasCliente(Object(res).data.recibo.cliente_id); */
            this.view_facturas =false
            //this.sucessoRecibo(Object(res).data)

            this.onReset();
          }

        } else {
          //this.errorReferenciasRecibo(Object(res).data)
        }
        this.loadingFacturacao = false
        this.configService.showAlert(Object(res).message, 'alert-success', true);
      }
    );
    this.configService.loaddinStarter('stop');
  }


  private activarAdiantamento = false
  /**
   * 
   */
  handleChangeFormaPagamento() {
    this.reciboForm.patchValue({
      valor_recebido: '',
      troco: 0,
      is_adiantamento: '0'
    });
    this.adiantamento = []
    this.listMovimento = []
    this.activarAdiantamento = false
    if (this.reciboForm.getRawValue().forma_pagamento_id != 1 && this.reciboForm.getRawValue().forma_pagamento_id != null) {
      this.reciboForm.get('data_pagamento').enable();
      this.reciboForm.get('referencia').enable();
      this.reciboForm.get('banco_id').enable();

      this.formasPagamentos.forEach(element => {
        if (element.designacao === 'Adiantamento' && this.reciboForm.getRawValue().forma_pagamento_id == element.id) {
          this.reciboForm.get('data_pagamento').reset();
          this.reciboForm.get('data_pagamento').disable();
          this.reciboForm.get('referencia').reset();
          this.reciboForm.get('referencia').disable();
          this.reciboForm.get('banco_id').reset();
          this.reciboForm.get('banco_id').disable();
          this.activarAdiantamento = true;
          this.getMovimentoAdiantamento();
        }

      });

    } else {

      this.reciboForm.get('data_pagamento').reset();
      this.reciboForm.get('data_pagamento').disable();
      this.reciboForm.get('referencia').reset();
      this.reciboForm.get('referencia').disable();
      this.reciboForm.get('banco_id').reset();
      this.reciboForm.get('banco_id').disable();
    }
  }


  printer_recibo: boolean = true;
  private series: any = []

  private getSeriesRecibos() {
    this.carregando.serie = 'Carregando...'
    this.http.call_get('serie/recibos', null).subscribe(
      response => {
        this.series = Object(response).data
        this.carregando.serie = 'Selecione a serie'
      }
    );
  }

  private formasPagamentos: any = [];

  private listarFormaPagamentos() {
    this.carregando.forma_pagamento = 'Carregando...'
    this.http.call_get('formaPagamento/formas', null).subscribe(
      response => {
        this.formasPagamentos = Object(response).data;
        this.configService.loaddinStarter('stop');
        this.carregando.forma_pagamento = 'Selecione a forma de pagamento'
      }
    );
  }



  private clientes: any = [];

  view_client = false;

  private getCliente() {
    this.adiantamento = []
    this.listMovimento = []
    this.facturas = [];
    this.facturasSeleciondas = []
    this.view_facturas = false;
    this.view_client = true;
    this.linha_pagamentos = []

    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.reciboForm.getRawValue().nome }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        this.getSeriesRecibos();
      }
    );
  }

  private setCliente(client: any) {
    this.createForm();

    this.reciboForm.patchValue({
      cliente_id: client.id,
      nome: client.nome,
    });
    this.view_client = false;
    this.facturasCliente(client.id);

  }

  view_facturas = false;
  private facturas: any = []
  private facturasCliente(client: number) {

    this.configService.loaddinStarter('start');

    this.http.__call('factura/notpay', { cliente_id: client }).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
          this.configService.loaddinStarter('stop');
        } else {
         
          this.facturas = Object(res).data;
          if(this.facturas == null) {
            this.configService.showAlert(Object(res).message, "alert-danger", true);
          } else {

            this.view_facturas = true
          }
          this.configService.loaddinStarter('stop');
        }
      }
    );
  }



  private totalApagar() {
    var ve = this.reciboForm.getRawValue().total_valor_recebido;
    this.printer_recibo = true;
    var total = 0;
    var total_saldado = 0
    this.reciboForm.patchValue({
      total_pago: null,
      total_saldado: null
    });

    this.facturasSeleciondas.forEach(element => {
      total += +element.valor_aberto.toFixed(2);
      var factura = element;
      factura.valor_aberto = (factura.valor_aberto == null ? factura.total : factura.valor_aberto);// Atribui o valor total caso o valor em aberto for null
      var va = factura.valor_aberto; // valor actual em aberto da factura  
      var saldado = 0  // recebe o valor saldado na factura
      if (ve > 0) {

        ve = ve - va;
        saldado = (ve < 0 ? ve - va * -1 : va) // calcula o valor saldado
        total_saldado += saldado
      }
    });

    this.reciboForm.patchValue({
      total_pago: +total.toFixed(3),
      total_saldado: +total_saldado.toFixed(3)
    });
  }

  private calcularTroco() {
    this.reciboForm.patchValue({
      troco: 0
    });


    if (this.reciboForm.getRawValue().total_pago > 0) {
      this.reciboForm.patchValue({
        troco: this.reciboForm.getRawValue().total_valor_recebido - this.reciboForm.getRawValue().total_pago
      });
      if (this.reciboForm.getRawValue().total_valor_recebido <= this.reciboForm.getRawValue().total_pago) {
        this.reciboForm.patchValue({
          troco: 0
        });
      }
    } else {
      this.reciboForm.patchValue({
        troco: 0
      });
    }
  }


  private btnImprimirRecibo() {
    this.PDFrecibo.imprimirRecibo(this.recibo, 'Original');
  }

  private facturasSeleciondas = []

  private selecionarFactura(factura: any) {

    if (this.facturasSeleciondas.length == 0) {
      this.facturasSeleciondas.push(factura);
    } else {
      var validar = 0
      for (let i = 0; i < this.facturasSeleciondas.length; ++i) {
        if (this.facturasSeleciondas[i].id === factura.id) {
          this.facturasSeleciondas.splice(i, 1);
          validar = 1;
        }
      }
      if (validar === 0) {
        this.facturasSeleciondas.push(factura);
      }
    }

    //Calcula o Total da Factura
    this.totalApagar();
    this.calcularTroco()
  }

  private listMovimento: any = [];
  adiantamento = []

  private getMovimentoAdiantamento() {
    this.configService.loaddinStarter('start');
    this.http.__call('adiantamento/factura/adiantamentoFactura', { cliente_id: this.reciboForm.getRawValue().cliente_id }).subscribe(
      response => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter('stop');
      });
  }


  setAdianta(ad: any) {

    if (this.adiantamento.length == 0) {
      this.reciboForm.patchValue({
        valor_recebido: ad.valor
      });
      this.adiantamento.push(ad);
    } else {
      var validar = 0
      var valor = 0
      for (let i = 0; i < this.adiantamento.length; ++i) {
        if (this.adiantamento[i].id === ad.id) {
          this.adiantamento.splice(i, 1);
          this.reciboForm.patchValue({
            valor_recebido: this.reciboForm.getRawValue().valor_recebido - ad.valor
          });
          validar = 1;
        }
        this.reciboForm.patchValue({
          valor_recebido: 0
        });
      }

      if (validar === 0) {

        this.reciboForm.patchValue({
          valor_recebido: this.reciboForm.getRawValue().valor_recebido + ad.valor
        });
        this.adiantamento.push(ad);
      }

    }
    this.calcularTroco()
  }


  /*sucessoRecibo(response: any) {
    var facts = ''
    response.facts.forEach(element => {
      facts += '<tr>'
        + '<td>' + element.factura_sigla + '</td>'
        + '<td>' + this.configService.numberFormat(element.saldado) + '</td>'
        + '<td>' + this.configService.numberFormat(element.va_ant) + '</td>'
        + '<td>' + this.configService.numberFormat(element.va_new) + '</td>'
        + '</tr>'
    });
    var html = `
    <div style="text-align: left; margin: 13px 20px; width: 700px; padding: 9px 23px; background: #f8f9fa; border-radius: 5px;">
      <h4>Estatística</h4>
      <hr />
      <table class="table table-hover table-bordered table-striped text-center">
          <thead>
              <tr>
                  <th>Factura</th>
                  <th>V.Saldado</th>
                  <th>V.Aberto Anterior</th>
                  <th>V.Aberto Novo</th>
              </tr>
          </thead>
          <tbody>
              ${facts}
          </tbody>
      </table>
      <b>Faturas Saldadas:</b>${(response.contTotalFacturaSaldadas)}<br />
      <b>Faturas em aberto: </b>${(response.contTotalFacturaAbertas)}<br />
      <b>Faturas não Avaliadas: </b>${(response.contTotalFacturaNaoAvaliadas)}
    </div>
    `
    const swalWithBootstrapButtons = Swal.mixin({

      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'icon-class',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-default',
        footer: 'footer-class'
      },
      width: '49em',
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({

      html: html,
      title: 'Recibo Gerado',
      text: "Você não poderá reverter isso!",
      type: 'success',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Imprimir',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //window.location.reload()
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Aguarde',
          'Aguarde, estamos a gerar a impressão do recibo',
          'success'
        )
        this.btnImprimirRecibo();
        //window.location.reload()
      }
    })

  }

  errorReferenciasRecibo(response: any) {
    var facts = ''
    // response.facts.forEach(element => {
    //   facts += '<tr>'
    //             + '<td>' + element.banco_nome + '</td>'
    //             + '<td>' + element.referencia_banco + '</td>' 
    //         + '</tr>'
    // });
    var html = '<div style="text-align: left;margin: 13px 20px;width: 700px;padding: 9px 23px;background: #f8f9fa;border-radius: 5px;">'
      + '<h4>As Referencias Bancarias listadas abaixo já foram utilizadas</h4><hr/>'
      + '<table class="table table-hover table-bordered table-striped text-center">'
      + '<thead>'
      + '<tr>'
      + '<th> Banco </th>'
      + '<th> Referencia </th>' 
      + '</tr>'
      + '</thead>'
      + '<tbody>'
      + facts +
      + ' </tbody>'
      + '</table>'
      + '</div>'

    const swalWithBootstrapButtons = Swal.mixin({

      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'icon-class',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'btn btn-success', 
        footer: 'footer-class'
      },
      width: '49em',
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({

      html: html,
      title: 'Erro Referencias Bancarias',
      text: "Você não poderá reverter isso!",
      type: 'error',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //window.location.reload()
      }
    })

  }*/


  //--------------------------------------------------------------
  private linha_pagamentos: any = []

  deleteRowFormaPagamento(linha: any) {
    for (let i = 0; i < this.linha_pagamentos.length; ++i) {
      if (this.linha_pagamentos[i].id === linha.id) {
        if (this.linha_pagamentos[i].designacao !== 'Adiantamento') {
          this.adiantamento = []
        }
        this.linha_pagamentos.splice(i, 1);
        //this.facturacao.pagamento.total_valor_recebido -= linha.valor_entrada;         
        this.calcularValorTotalRecebido();
        this.totalApagar()
        this.calcularTroco()


      }
    }

  }

  private addLinhaPagamentos() {
    var validar = 0;
    var forma = null
    var banco_nome = null

    this.configService.bancos.forEach(element => {
      if (this.reciboForm.getRawValue().banco_id == element.id) {
        banco_nome = element.abreviatura + ' - ' + element.numero_conta;
      }
    });

    this.formasPagamentos.forEach(element => {
      if (this.reciboForm.getRawValue().forma_pagamento_id == element.id) {
        forma = element.designacao;
      }
    });

    if (forma == null) {
      this.configService.showAlert('Informa uma forma pagamento valida', "alert-danger", true);
      return;
    } else if (this.reciboForm.getRawValue().valor_recebido == '' || this.reciboForm.getRawValue().valor_recebido < 0) {
      this.configService.showAlert('Informa Valor de Entrada valido', "alert-danger", true);
      return;
    } else if(_.sum(_.map(this.facturasSeleciondas,'total')) < (_.sum(_.map(this.linha_pagamentos,'valor_entrada')) + this.reciboForm.getRawValue().valor_recebido)){
      this.configService.showAlert('O valor total pago é maior que o valor a pagar', "alert-danger", true);
      return;
    }
    else
    {

      var linha = {
        designacao: forma,
        valor_entrada: this.reciboForm.getRawValue().valor_recebido,
        data_pagament: this.reciboForm.getRawValue().data_pagamento,
        id: this.reciboForm.getRawValue().forma_pagamento_id,
        referencia_banco: this.reciboForm.getRawValue().referencia,
        banco_id: this.reciboForm.getRawValue().banco_id,
        banco_nome: banco_nome
      }

      if (this.linha_pagamentos.length >= 1) {
        for (let index = 0; index < this.linha_pagamentos.length; index++) {
          const l = this.linha_pagamentos[index];
          if (l.id == linha.id) {
            this.linha_pagamentos.splice(index, 1);
            this.linha_pagamentos.splice(index, 0, linha);
            validar = 1;
          }
        }
        if (validar == 0) {
          this.linha_pagamentos.push(linha);
        }
      } else {
        this.linha_pagamentos.push(linha);
      }

      this.calcularValorTotalRecebido();
      this.totalApagar()
      this.calcularTroco()


      this.reciboForm.get('forma_pagamento_id').reset();
      this.reciboForm.get('valor_recebido').reset();
      this.reciboForm.get('data_pagamento').reset();
      this.reciboForm.get('referencia').reset();
      this.reciboForm.get('banco_id').reset();

      this.reciboForm.get('referencia').disable();
      this.reciboForm.get('data_pagamento').disable();
      this.reciboForm.get('banco_id').disable();

    }

  }

  private calcularValorTotalRecebido() {
    var total_valor_recebido = 0
    this.linha_pagamentos.forEach(element => {
      total_valor_recebido += ((element.valor_entrada));
    });
    this.reciboForm.patchValue({
      total_valor_recebido: total_valor_recebido
    });
  }


  private codeReferencia: any;
  private validationReferenciaBancaria(ref, bank) {
    this.http.__call('referencia/validation', { referencia: ref, banco_id: bank }).subscribe(
      response => {
        this.codeReferencia = Object(response);
      }
    );
  }

}

