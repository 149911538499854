import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { first } from 'rxjs/operators';


export const serieCodeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const code = control.value;

    if (!code) {
        return null;
    }

    const codeIsValid = /^[a-zA-Z0-9]+$/.test(code);

    return code && codeIsValid ? null : { 'invalidCode': true };
};

export const reasonForInactivation: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  if (!control.parent) {
    return null;
  }

  let active = control.parent.controls['activo'].value  

  if (active == '1') {
    return null
  } else if (active == '0') {
    const code = control.value;
    if (!code) {
      return { 'invalidCode': true }
    }

    return code.length < 10 ? { 'invalidCode': true } : null
  }
};

@Component({
  selector: 'createOrEditSerie',
  templateUrl: './create-or-edit-serie.component.html',
  styleUrls: ['./create-or-edit-serie.component.css']
})
export class CreateOrEditSerieComponent implements OnInit {
  @Input() modal: string = "modalSerieCreateOrEdit";
  @Input() title: string = "Registar Serie";
  @Input() serie: any;
  @Input() documentos:any = [];

  submitted = false;
  private loading: boolean = false;
  private editingMode: boolean = false;

  @Input() simpleFormSerie: FormGroup;

  @Output() private loadListSerie = new EventEmitter<any>();


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.getDocumentos();
  }

  createForm() {
    this.simpleFormSerie = this.formBuilder.group({
        index: [{ value: null, disabled: true }],
        documento_id: [null, Validators.required],
        nome: [null, Validators.required],
        code: [null],
        proximo_numero: [1],
        movimento: ['N'],
        tipo_movimento: ['E'],
        activo: [null],
        descricao: [null],
        has_sales_documents: [0],
        reason_for_inactivation: ['']
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormSerie.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormSerie.reset();
    this.simpleFormSerie.patchValue({
        proximo_numero: 1
    });
  }

  onSubmit(e) {
    this.submit(e);
    /*if (this.simpleFormSerie.get('activo').value == 0 && e.submitter.id !== "validate") {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons.fire({
        title: 'Tem a certeza que deseja inativar a série?',
        text: "Esta ação não pode ser revertida!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then((result) => {
        if (!result.value) {
          return;
        } else {
          this.submit(e);
        }
      });
    } else {
      this.submit(e);
    }*/
  }

  submit(e) {
    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormSerie.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormSerie.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'serie/create' : 'serie/update/' + index);
    this.createOrEdit(uri, this.simpleFormSerie, (index === null ? true : false));
  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
            this.loadListSeries(Object(response).data);
        }

        if (Object(response).code == 200) {
            this.loadListSeries(Object(response).data);
        }

        this.simpleFormSerie.reset();
        this.simpleFormSerie.patchValue({
            proximo_numero: 1,
            movimento: 'N',
            tipo_movimento: 'E'
        });
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.getDocumentos();
    if (this.serie !== undefined ) {
      this.editingMode = true;
      this.title = "Editar serie";
      this.simpleFormSerie.patchValue({
        index: this.serie.id,
        documento_id: this.serie.documento_id,
        nome: this.serie.nome,
        code: this.serie.code,
        proximo_numero: this.serie.proximo_numero,
        movimento: this.serie.movimento,
        tipo_movimento: this.serie.tipo_movimento,
        activo: this.serie.activo,
        descricao: this.serie.descricao,
        has_sales_documents: this.serie.has_sales_documents,
        reason_for_inactivation: this.serie.reason_for_inactivation,
      });
    } else {
      this.editingMode = false;
      this.title = "Registar Serie";
    }
  }

  public loadListSeries(serie) {
    this.loadListSerie.emit(serie);
  }

/**
   * @name "Listar Documentos"
   * @descriptio "Esta Função permite Listar todos Documentos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private getDocumentos() {
    this.http.__call('documento/show', null).subscribe(
      data => {
        this.documentos = data;
      }
    );
  }

}
