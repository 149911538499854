import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-banco',
  templateUrl: './banco.component.html',
  styleUrls: ['./banco.component.css']
})
export class BancoComponent implements OnInit {

  private banco = {
    id: null,
    nome: null,
    abreviatura: null,
    numero_conta: null,
    iban: null,
    activo: null
  }


  

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() { 
  }
  @Input() bancos: any = [];
  /**
  * @name "Listar bancos"
  * @descriptio "Esta Função permite Listar todas bancos"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private listarBancos() {

    this.configService.loaddinStarter('start');
    this.http.call_get('banco/listar', null).subscribe(
      response => {
        this.bancos = Object(response).data; 
        this.configService.loaddinStarter('stop');
      }
    );
  }

  /**
  * @name "Registar banco"
  * @description "Esta Função permite Registar bancos"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private register() {

    if (this.banco.nome == null || this.banco.abreviatura == null || this.banco.activo == null) {
      this.configService.showAlert('Os campos nome, abreviação, iban e activo, são de caracter obrigatorio', 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('banco/register', this.banco).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.listarBancos();
            this.ini();
            this.configService.loaddinStarter('stop');
          }
        }
      );
    }
  }

  private ini() {
    this.banco = {
      id: null,
      nome: null,
      abreviatura: null,
      numero_conta: null,
      iban: null,
      activo: null
    }
  }


}
