import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-lista-orcamento',
  templateUrl: './lista-orcamento.component.html',
  styleUrls: ['./lista-orcamento.component.css']
})
export class ListaOrcamentoComponent implements OnInit {

  @ViewChild("serie") serie;
  @ViewChild("orderBy") orderBy;
  @ViewChild("observacao") observacao;
  @ViewChild("serieRecibo") serieRecibo;
 


  private totalFacturacao = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private serieId: number;
  private serieReciboId: number;
  private series: any = [];
  private seriesRecibos: any = [];


  private alert = {
    message: null,
    show: false,
    class: null
  }
  private orcamentos: any = [];
  private dadosFacturaId: any = [];
  private produtosNotaCredito: any = [];

  private btnClickBoleon = false;

  private factura = {
    factura_id: null,
    factura_sigla: null,
    status_reason: null,
    status_date: null,
    status: null,
    numero: null,
    pago: null,
    created_at: null,
    cliente_id: null,
    cliente_nome: null,
    serie: null,
    documento: null,
    sigla: null,
    total: null,
    totalComImposto: null,
    totalSemImposto: null,
  }

  private dashboard = {
    countRecibos: null,
    countFacturas: null,
    countFacturasVencidas: null,
    countFacturasContaCorrente: null
  }
  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1); 
  }


  

  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarOrcamentos() {
    this.http.filters.filterDocumentsFacturacao=['OR'];

    this.configService.loaddinStarter('start');
    this.http.__call('orcamento/list', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.orcamentos = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  //--------------------------------------------------------------------------

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarOrcamentos();
  }
  //--------------------------------------------------------------------------

 

  private btnImprimirFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via");
  }
 

}
