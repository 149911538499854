import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.css']
})
export class CompraComponent implements OnInit {
  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  private compra = {
    fornecedor_id: null,
    dataCompra: null,
    produto_id: null,
    preco: null,
    produtos: [],
    total:0
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,
    start: 1,
    end: 10,
    search: null,
    order: "compras.created_at"
  };

  private produtos: any = [];
  private compras: any = [];
  private fornecedores: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.listarCompras(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);
    this.selectProdutos();
    this.selectFornecedores();
  }


  private addProdut() {
    let validar = 0;
    if (this.compra.produto_id != null && this.compra.preco != null) {
      if (this.compra.produtos.length > 0) {
        for (let index = 0; index < this.compra.produtos.length; index++) {
          const produt = this.compra.produtos[index];
          if (produt.id == this.compra.produto_id) {
            produt.valor = this.compra.preco;
            this.compra.produtos.splice(index, 1);
            this.compra.produtos.splice(index, 0, produt);
            validar = 1;
          }

        }
        if (validar == 0) {
          this.produtos.forEach(element => {
            if (element.id == this.compra.produto_id) {
              element.valor = this.compra.preco;
              this.compra.produtos.push(element);
            }
          });
        }
      } else {
        this.produtos.forEach(element => {
          if (element.id == this.compra.produto_id) {
            element.valor = this.compra.preco;
            this.compra.produtos.push(element);
          }
        });
      } 
      this.compra.produto_id = null;
      this.compra.preco = null;
      this.calcularTotal()
    } else {
      this.configService.showAlert("Informe o produto e o seu preço", 'alert-danger', true);
    }
  }

  private calcularTotal() {
    this.compra.total = 0; 
    //Calcula o Total da compra
    for (let index = 0; index < this.compra.produtos.length; index++) { 
      this.compra.total += this.compra.produtos[index].valor;
    } 
 
  }

  deleteRow(id) {
    for (let i = 0; i < this.compra.produtos.length; ++i) {
      if (this.compra.produtos[i].id === id) {
        this.compra.produtos.splice(i, 1);
      }
    }
  }

  private selectProdutos() {
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      res => {
        this.produtos = Object(res).data;
      }
    );
  }

  private selectFornecedores() {
    this.http.call_get('fornecedor/selectFornecedores', null).subscribe(
      res => {
        this.fornecedores = Object(res).data;
      }
    );
  }

  /**
 * @name "Registar Comora"
 * @description "Esta Função permite efectuar registo de compra de produtos"
 * @author "caniggia.moreira@itgest.pt"
 * @param start 
 * @param end 
 */
  private register() {
    (this.compra);
    if (this.compra.dataCompra == null || this.compra.fornecedor_id == null || this.compra.produtos.length == 0) {
      this.configService.showAlert("Informe o fornecedor, data da compra, e uma lista de produtos", 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('compra/register', {compra:this.compra}).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.compra = {
              fornecedor_id: null,
              dataCompra: null,
              produto_id: null,
              preco: null,
              produtos: [],
              total: 0
            }
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
          }
        }
      );
    }
  }


  ///----------------------------------------------------

  /**
  * @name "Listar Compras"
  * @descriptio "Esta Função permite Listar todas Compras"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private listarCompras(start, end, search) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search 
    this.http.__call('compra/listar', this.pagination).subscribe(
      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;
        this.compras = Object(response).data.data;
        (this.compras );
        this.gerarPages();
        this.configService.loaddinStarter('stop');
      }
    );
  }



  private searchCompras() {
    this.pagination.page = 1;
    this.listarCompras(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);
  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarCompras(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarCompras(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value );

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarCompras(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.listarCompras(page, this.pagination.perPage, this.search.nativeElement.value);
  }
}
