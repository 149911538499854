import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})
export class DocumentoComponent implements OnInit {

  private documento = {
    nome: null,
    sigla: null
  }


  private pagination = {
    start: 1,
    end: 10
  };

  private items: any = [{
    "nome" : "Factura",
    "sigla" : "Fact"
  }];

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.listarDocumentos(1, 8);
  }

  /**
   * @name "Listar Documentos"
   * @descriptio "Esta Função permite Listar todos Documentos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private listarDocumentos(start, end) {
    this.pagination.start = start;
    this.pagination.end = end;
    this.http.__call('documento/listar', this.pagination).subscribe(
      data => {
        this.items = data;
      }
    );
  }
  /**
   * @name "Registar Documentos"
   * @description "Esta Função permite Registar Documentos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private register(e) {
    e.preventDefault();
    if (e.target.elements[0].value && e.target.elements[1].value) {
      this.documento.nome = e.target.elements[0].value;
      this.documento.sigla = e.target.elements[1].value;
      this.http.__call('documento/create', this.documento).subscribe(
        data => {
         this.clearFormInputs(e);
          this.listarDocumentos(1, 10)
        }
      );
    }
  }

  private clearFormInputs(e){
    e.target.elements[0].value="";
    e.target.elements[1].value="";
  }
}

