import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-tarifario',
  templateUrl: './tarifario.component.html',
  styleUrls: ['./tarifario.component.css']
})
export class TarifarioComponent implements OnInit {
  
  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  private tarifario = {
    id: null,
    descricao: null,
    condicoes: null,
    credMensalInDefault: null,
    plano_preco_id: null,
    estado: true,
    dataEstado: null
  }


  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "i",
    order: "tarifarios.created_at",
    searchData: ''
  };


  private items: any = [];
  private armazens: any = [];
  private planoprecos: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
   
    this.listarTarifarios(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
    this.SelectPlanoPreco();
  }

  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private listarTarifarios(start, end, search, searchData, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search
    this.pagination.order = orderBy;
    this.pagination.searchData = searchData;
    this.http.__call('tarifario/listagem', this.pagination).subscribe(
      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        //console.log(this.items )
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

 
  private searchTarifario(e) {
    this.pagination.page = 1;
    this.listarTarifarios(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarTarifarios(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarTarifarios(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarTarifarios(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.listarTarifarios(page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }



  /**
   * @name "Registar series"
   * @description "Esta Função permite Registar series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault(); 
    this.http.__call('tarifario/create', this.tarifario).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.SelectPlanoPreco();
          this.listarTarifarios(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
          this.configService.loaddinStarter('stop');
        }
      }
    )
    ;
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = "";
    e.target.elements[1].value = "";
  }


  /**
   * @name "Listar Documentos"
   * @descriptio "Esta Função permite Listar todos Documentos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private SelectPlanoPreco() {
    this.http.__call('planoPreco/show', null).subscribe(
      data => {
        this.planoprecos = data;
      }
    );
  }

  private refreshTarifario(id) {

    this.items.forEach(element => {
      if (element.id == id) {
        this.tarifario.id = element.id;
        this.tarifario.descricao = element.descricao;
        this.tarifario.condicoes = element.condicoes;
        this.tarifario.credMensalInDefault = element.credMensalInDefault;
        this.tarifario.dataEstado = element.dataEstado;
        this.tarifario.estado = element.estado;
        this.tarifario.plano_preco_id = element.plano_preco_id; 
      }
    });
  }

  /**
   * @name "Editar series"
   * @description "Esta Função permite Registar series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    //console.log(this.tarifario)
    if (this.tarifario.descricao == "") {
      this.configService.showAlert("Digite a desrição", 'alert-danger', true);
    } else {
      this.http.__call('tarifario/update/' + this.tarifario.id, this.tarifario).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarTarifarios(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
            this.configService.loaddinStarter('stop');
          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini(){
    this.tarifario = {
      id: null,
      descricao: null,
      condicoes: null,
      plano_preco_id: null,
      credMensalInDefault: null,
      dataEstado: null,
      estado: false
    }
  }




}
