import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avenca',
  templateUrl: './avenca.component.html',
  styleUrls: ['./avenca.component.css']
})
export class AvencaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
