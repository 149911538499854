import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';


@Component({
  selector: 'app-fornecedor',
  templateUrl: './fornecedor.component.html',
  styleUrls: ['./fornecedor.component.css']
})
export class FornecedorComponent implements OnInit {

  @ViewChild("search") search; 
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado; 

  private fornecedor = {
    id: null,
    nome: null,
    telefone: null,
    localizacao: null,
    nif: null
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "created_at"
  };

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);

  }

  private pesquisarFornecedor(e) {
    this.pagination.page = 1;
    this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);
  }

  private listFornecedors(start, end, search) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = (search == "" ? "a" : search); 

    this.http.__call('fornecedor/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (e.target.elements[0].value == "" || e.target.elements[1].value == "" || e.target.elements[3].value == "") {

      this.configService.showAlert('É obrigatório fornecer o nome, telefone, nif e localizacao do fornecedor', 'alert-danger', true);

    } else {
      /*this.fornecedor.nome = e.target.elements[0].value;
        this.fornecedor.telefone = e.target.elements[1].value;
        this.fornecedor.localizacao = e.target.elements[3].value;
        this.fornecedor.nif = (e.target.elements[2].value == "" ? "999999999" : e.target.elements[2].value);*/
      console.log(this.fornecedor)
      this.http.__call('fornecedor/register', this.fornecedor).subscribe(
        data => {
          if (Object(data).code == 500) {
            this.configService.showAlert(Object(data).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);
            this.configService.clearFormInputs(e);
          }

        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private preenchAuto() {
    console.log(this.fornecedor.nif.length);
    if (this.fornecedor.nif.length > 8) {
      this.http.call_get('cliente/search-cliente-agt/' + this.fornecedor.nif, null).subscribe(
        response => {
          console.log(Object(response).data);
          if (Object(response).data != null) {
            this.fornecedor.nome = Object(response).data.nomeContribuinte;
            this.fornecedor.telefone = (Object(response).data.telefone == 'NA' ? '' : Object(response).data.telefone);
          } else {
            this.fornecedor.nome = null
            this.fornecedor.telefone = null
          }
        }
      );
    }

  }


  private refreshDataFornecedor(id, nome, nif, telefone, localizacao) {
    this.fornecedor.id = id;
    this.fornecedor.nome = nome;
    this.fornecedor.nif = nif;
    this.fornecedor.telefone = telefone;
    this.fornecedor.localizacao = localizacao;

  }

  private editar(e) {
    e.preventDefault();
    if (e.target.elements[0].value == "" || e.target.elements[1].value == "" || e.target.elements[2].value == "" || e.target.elements[3].value == "") {
      this.configService.showAlert('É obrigatório fornecer o nome, telefone, nif e localizacao do fornecedor', "alert-danger", true);
    } else {
      this.configService.loaddinStarter('start');

      this.fornecedor.nome = e.target.elements[0].value;
      this.fornecedor.telefone = e.target.elements[1].value;
      this.fornecedor.localizacao = e.target.elements[3].value;
      this.fornecedor.nif = (e.target.elements[2].value == "" ? "999999999" : e.target.elements[2].value);
      console.log(this.fornecedor);

      this.http.__call('fornecedor/update/' + this.fornecedor.id, this.fornecedor).subscribe(
        response => {

          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(response).message, 'alert-sucess', true);

          this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);

        }
      );
    }
  }
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value );
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listFornecedors(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value);
  }

}


