import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { copyStyles } from '@angular/animations/browser/src/util';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard'
import * as moment from 'moment';
import _ from 'lodash';

@Component({
  selector: 'app-facturacao',
  templateUrl: './facturacao.component.html',
  styleUrls: ['./facturacao.component.css']
})
export class FacturacaoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("searchDiscount") searchDiscount;
  @ViewChild("qtd") qtd;

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;
  @ViewChild("descricao") descricao;
  @ViewChild('closebutton') closebutton;
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  private desconto: number = 0;

  private dataActual = new Date();
  private totalFacturacao = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private produts: any = [];
  private clientes: any = [];
  private quantidade = 1;
  private produtoId: number;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false

  private contas_clientes: any = [];
  private servicos_conta_cliente: any = [];


  private numero_telefone: string;
  private searchCliente: string;

  private isFinalizar: boolean = true;


  private ckeditor = {
    descricao: null
  }

  private facturacao = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,

      conta_id: null,
      servico_id: null
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null
    },
    globalDiscountPercentage: 0,
    produto: [],
    cloneProducts: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamento: [],
      is_adiantamento: 0,
      linha_pagamentos: []
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_facturacao: false,
    facturaGerada: null,
    data_vencimento: null,
    moeda: null
  }

  private disabledPagamento = true;
  private disabledPagamentoAdiantamento = true


  private series: any = [];
  private activeButtom = {
    cliente: false,
    produto: false
  }

  private items_produtos: any = [];
  private pagination = {
    start: 1,
    end: 10,
    search: null,
    servico: null
  };

  private produto_selecionado = null;
  private preco: number;




  constructor(private http: HttpService, private configService: ConfigService,public toasterService: ToastrService) { }
  ngOnInit() {


    this.ListarClientes(1, 100, null);

    this.configService.listarBancos();
    this.configService.listarMoedas();
  }


  private ListarClientes(start, end, searchCliente) {
    this.configService.loaddinStarter('start');
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = searchCliente

    this.http.__call('cliente/search-cliente', this.pagination).subscribe(
      res => {
        this.clientes = Object(res).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }
  private getCliente(e) {
    this.searchCliente
    this.ListarClientes(1, 10, this.searchCliente);
  }

  private getProduto() {
    console.log(this.search.nativeElement)
    this.produtos(this.search.nativeElement.value);
  }

  private goToFinalizeStep(){
    if (!(this.facturacao.globalDiscountPercentage <= 100 && this.facturacao.globalDiscountPercentage >= 0)) {
      this.toasterService.error('Dados inválidos', 'Desconto Global inválido, informe um valor no intervalo entre 0 e 100%');
      return;
    }  
     this.calcularTotal();
     this.wizard.navigation.goToNextStep();
    return
  }

  private listarProdutos(response: any) {
    if (response.total == null) {
      this.configService.showAlert("Não foi informado nenhum preço no produto", "alert-danger", true);
      return;
    }
    this.facturacao.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.facturacao.produto.length >= 1) {
        if (response.valor_original) {
          for (let index = 0; index < this.facturacao.produto.length; index++) {
            const produt = this.facturacao.produto[index];
            if (produt.produto_id == response.produto_id) {

              this.facturacao.produto.splice(index, 1);
              this.facturacao.cloneProducts.splice(index, 1);
              this.facturacao.produto.splice(index, 0, response);
              this.facturacao.cloneProducts.splice(index, 0, response);
              validar = 1;
            }
          }
        }

        if (validar == 0) {
          this.facturacao.cloneProducts.push(_.cloneDeep(response));
          this.facturacao.produto.push(response);
        }
      } else {
        this.facturacao.produto.push(response);
        this.facturacao.cloneProducts.push(_.cloneDeep(response));
      }

      // Calcula o Total da Factura
      this.calcularTotal();
    }
  }

  private calcularTotal() {
    this.facturacao.total = 0;
    this.facturacao.totalSemImposto = 0;
    this.facturacao.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.produto.length; index++) {

      if(this.facturacao.globalDiscountPercentage != 0 ) {

        this.facturacao.produto[index].linhaTotalSemImposto = +this.facturacao.cloneProducts[index].linhaTotalSemImposto.toFixed(3)
        this.facturacao.produto[index].valorImposto = +this.facturacao.cloneProducts[index].valorImposto.toFixed(3)
        this.facturacao.produto[index].total = +this.facturacao.cloneProducts[index].total.toFixed(3)

        this.facturacao.produto[index].globalLineDiscount = (this.facturacao.produto[index].linhaTotalSemImposto * (this.facturacao.globalDiscountPercentage / 100))
        this.facturacao.produto[index].linhaTotalSemImposto -= this.facturacao.produto[index].globalLineDiscount
        this.facturacao.produto[index].valorImposto = (this.facturacao.produto[index].linhaTotalSemImposto * (this.facturacao.produto[index].taxValuePercentage / 100))
        this.facturacao.produto[index].total = this.facturacao.produto[index].linhaTotalSemImposto +  this.facturacao.produto[index].valorImposto
      } else if(this.facturacao.cloneProducts.length > 0){

        this.facturacao.produto[index].globalLineDiscount = 0
        this.facturacao.produto[index].linhaTotalSemImposto = this.facturacao.cloneProducts[index].linhaTotalSemImposto
        this.facturacao.produto[index].valorImposto = this.facturacao.cloneProducts[index].valorImposto
        this.facturacao.produto[index].total = this.facturacao.cloneProducts[index].total
      }
      this.facturacao.totalSemImposto += this.facturacao.produto[index].linhaTotalSemImposto;

      this.facturacao.totalComImposto += this.facturacao.produto[index].valorImposto;

      this.facturacao.total += this.facturacao.produto[index].total;
    }
    this.facturacao.pagamento.total_pago = Math.round(this.facturacao.total * 100) / 100;
    this.facturacao.pagamento.valor_recebido = Math.round(this.facturacao.total * 100) / 100;

    if (this.facturacao.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  deleteRow(produto: any) {
    for (let i = 0; i < this.facturacao.produto.length; ++i) {
      if (this.facturacao.produto[i].produto_id === produto.produto_id) {
        this.facturacao.produto.splice(i, 1);
        this.calcularTotal();
      }
    }

    for (let i = 0; i < this.facturacao.cloneProducts.length; ++i) {
      if (this.facturacao.cloneProducts[i].produto_id === produto.produto_id) {
        this.facturacao.cloneProducts.splice(i, 1);
      }
    }
  }

  private refreshProdutoId(produto: any) {
    this.produtoId = produto.id;
    this.produto_selecionado = produto.valor;
  }

  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }

  }
  private selectedSerie() {
    this.series.forEach(element => {
      if (this.facturacao.serie.id == element.id) {
        if (element.sigla == 'FT') {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.facturacao.serie.id = element.id
        this.facturacao.serie.nome = element.nome
        this.facturacao.serie.sigla = element.sigla
        this.facturacao.serie.proximo_numero = element.proximo_numero
      }
    });
  }

  private selectedCliente(id) {

    this.facturacao.cliente.id = id;

    this.clientes.forEach(element => {
      if (this.facturacao.cliente.id == element.id) {

        this.facturacao.cliente.id = element.id;
        this.facturacao.cliente.nome = element.nome;
        this.facturacao.cliente.contribuinte = element.contribuente;
        this.facturacao.cliente.telefone = element.telefone;
        this.facturacao.cliente.morada = element.morada;

        this.activeButtom.cliente = true;
      }
    });

    this.listarseries();
    this.listarFormaPagamentos();
    this.produtos(null);
    this.facturacao.produto = [];
  }

  private produtos(search) {
    this.items_produtos = [];
    this.configService.loaddinStarter('start');
    this.pagination.search = (search == "" || search == null ? '' : search);
 
    this.http.__call('artigo/search', this.pagination).subscribe(
      res => { 
        this.items_produtos = Object(res).data
        this.configService.loaddinStarter('stop');
      }
    );
  }

  // verificar se o produto tem preço.
  // se não, permitir adicionar o preço
  private updateBancList(banco: any) {

    this.facturacao.pagamento.bancos.push(banco);
  }

  getAddProduto() {
    if (this.quantidade <= 0 || isNaN(this.quantidade)) {
      this.toasterService.error('Dados inválidos', 'Não foi informada uma quantidade válida');
      return;
    }
    if (!(this.desconto <= 100 && this.desconto >= 0)) {
      this.toasterService.error('Dados inválidos', 'Desconto inválido, informe um valor no intervalo entre 0 e 100%');
      return;
    }

    this.configService.loaddinStarter('start');
    this.http.__call('artigo/pesquisar',
      {
        produto_id: this.produtoId,
        quantidade: (this.quantidade < 0 || isNaN(this.quantidade) ? 1 : this.quantidade),
        desconto: this.desconto,
        cliente_id: this.clienteId,
        observacao: this.ckeditor.descricao,
        //observacao: this.descricao.nativeElement.value,
        globalDiscount: this.facturacao.globalDiscountPercentage,
        preco: this.preco
      }
    ).subscribe(
      res => {
        this.listarProdutos(Object(res).data);
        if (this.produto_selecionado == 0) {
          this.produtos(this.search.nativeElement.value);
        }
        this.quantidade = 1;
        this.desconto = 0;
        this.preco = 0;
        this.ckeditor.descricao = null;
        this.configService.loaddinStarter('stop');
      }
    );

    this.closebutton.nativeElement.click();
  }

  private facturar() {
  
    if (this.facturacao.cliente.id == null) {
      this.configService.showAlert('É obrigatório fornecer um cliente', "alert-danger", true);
    } else if (this.facturacao.serie.id == null) {
      this.configService.showAlert('É obrigatório fornecer uma serie', "alert-danger", true);
    } else if (this.facturacao.produto.length == 0) {
      this.configService.showAlert('É obrigatório fornecer produtos para a factura', "alert-danger", true);
    } else {
      if (this.facturacao.serie.sigla == 'FR' || this.facturacao.serie.sigla == 'FS') {
        if (this.facturacao.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert('É obrigatório fornecer uma forma de pagamento', "alert-danger", true);
        } else if (this.facturacao.pagamento.total_valor_recebido < +this.facturacao.total.toFixed(2) || this.facturacao.pagamento.total_valor_recebido == 0) {
          this.configService.showAlert('O valor a pagar não pode ser diferente do total do documento', "alert-danger", true);
        } else {
          this.guardarFactura();
        }
      } else {
        this.guardarFactura();
      }
    }
  }

  isPagamento() {
    this.disabledPagamentoAdiantamento = false
    // this.facturacao.pagamento.valor_recebido = 0.0
    this.facturacao.pagamento.referencia = null
    this.facturacao.pagamento.data_pagamento = null

    if (this.facturacao.serie.sigla == 'FR' || this.facturacao.serie.sigla == 'FS') {
      var a: string = null
      this.formasPagamentos.forEach(element => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (element.designacao === 'Adiantamento' || element.designacao === 'adiantamento') {
            a = element.designacao
          }
        }
      });

      if (this.facturacao.pagamento.forma_pagamento_id != 1 && this.facturacao.pagamento.referencia == null) {
        if (a !== 'Adiantamento') {
          this.disabledPagamento = false;

        } else {
          this.disabledPagamento = true;
          this.disabledPagamentoAdiantamento = true;
          this.facturacao.pagamento.is_adiantamento = 1;
        }
      } else {
        this.disabledPagamento = true;
      }
    }
  }


  private guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter('start');

    this.http.__call('factura/create', {
      produtos: this.facturacao.produto,
      documento: 'Fatura',
      cliente: this.facturacao.cliente.id,
      total: this.facturacao.total,
      serie_id: this.facturacao.serie.id,
      totalComImposto: this.facturacao.totalComImposto,
      totalSemImposto: this.facturacao.totalSemImposto,
      globalDiscountPercentage: this.facturacao.globalDiscountPercentage,
      observacao: this.observacao.nativeElement.value,
      numero_origem_factura: null,
      data_origem_factura: null,
      data_vencimento: this.facturacao.data_vencimento,
      pagamento: this.facturacao.pagamento,
      contas_cliente: this.facturacao.cliente,
      moeda: this.facturacao.moeda

    }).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.configService.showAlert(Object(res).message, "alert-success", true);
          //this.configService.imprimirFactura(Object(res).data.id, "Original");
          this.iniFacturacao();
          this.facturacao.success_facturacao = true;
          this.facturacao.facturaGerada = Object(res).data.id

        } else {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
          this.isFinalizar = true;
        }
        this.configService.loaddinStarter('stop');
      }
    );
  }




  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */

  private listarseries() {
    this.http.__call('serie/selectBoxSeries', { documentos: ['FT', 'FR', 'ND', 'FS']}).subscribe(
      data => {
        this.series = Object(data).data;

        if (this.series.length == 1) {

          this.series.forEach(element => {
            if (element.sigla == 'FT') {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }

            this.facturacao.serie.id = element.id;
            this.facturacao.serie.nome = element.nome;
            this.facturacao.serie.sigla = element.sigla;
            this.facturacao.serie.proximo_numero = element.proximo_numero;
          });
        }
      }
    );
  }



  private listarFormaPagamentos() {

    this.configService.loaddinStarter('start');
    this.http.call_get('formaPagamento/formas', null).subscribe(
      response => {
        this.formasPagamentos = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }
 

  private iniFacturacao() {


    this.facturacao = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,

        conta_id: null,
        servico_id: null
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null
      },
      globalDiscountPercentage: 0,
      produto: [],
      cloneProducts: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamento: null,
        is_adiantamento: 0,
        linha_pagamentos: []

      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total: 0.0,
      success_facturacao: false,
      facturaGerada: null,
      data_vencimento: null,
      moeda: null
    }
  }

  private reloadFacturacao() {
    location.reload();
  }

  private getContas() {
    this.activeButtom.cliente = false
    this.http.call_get('cliente/conta/selectBox/' + this.facturacao.cliente.id, null).subscribe(
      response => {
        this.contas_clientes = Object(response).data
      })
  }

  private getServicosConta() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter('start');
    this.http.call_get('tarifario/servico/selectBoxServicosConta/' + this.facturacao.cliente.conta_id, null).subscribe(
      response => {
        this.servicos_conta_cliente = Object(response).data
        this.configService.loaddinStarter('stop');
      })

  }

  confirmarConta() {
    this.activeButtom.cliente = false
    if (this.facturacao.cliente.conta_id == null || this.facturacao.cliente.servico_id == null) {
      this.configService.showAlert('A conta e serviço são obrigatorio', "alert-danger", true);
    } else {
      this.activeButtom.cliente = true
    }
  }

  

  private addLinhaPagamentos() {
    var validar = 0;

    if (this.facturacao.serie.sigla == 'FR' || this.facturacao.serie.sigla == 'FS') {
      let a: string = null;

      this.formasPagamentos.forEach(element => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (element.designacao === 'Adiantamento' || element.designacao === 'adiantamento') {
            a = element.designacao;
            this.facturacao.pagamento.referencia = '000000';
            this.facturacao.pagamento.data_pagamento = moment(new Date()).format('YYYY-MM-DD');
          }
        }
      });

      if (this.facturacao.pagamento.valor_recebido <= 0) {
        this.configService.showAlert('Informa um valor de superior a zero', "alert-danger", true);
      } else if (this.facturacao.pagamento.forma_pagamento_id == null || this.facturacao.pagamento.forma_pagamento_id == "null") {
        this.configService.showAlert('Informa uma forma pagamento válida', "alert-danger", true);
      } else if (this.facturacao.pagamento.forma_pagamento_id == 2 && this.facturacao.pagamento.referencia == null) {
        if (a !== 'Adiantamento') {
          this.configService.showAlert('Referencia a bancaria', "alert-danger", true);
        }
      } else if (this.facturacao.pagamento.forma_pagamento_id == 2 && this.facturacao.pagamento.data_pagamento == null) {
        if (a !== 'Adiantamento') {
          this.configService.showAlert('Informa a data de pagamento válida!', "alert-danger", true);
        }
      } else {

        this.formasPagamentos.forEach(element => {
          if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
            this.facturacao.pagamento.forma = element.designacao;
          }
        });

        const linha = {
          designacao: this.facturacao.pagamento.forma,
          valor_entrada: this.facturacao.pagamento.valor_recebido,
          data_pagament: this.facturacao.pagamento.data_pagamento,
          id: this.facturacao.pagamento.forma_pagamento_id,
          referencia_banco: this.facturacao.pagamento.referencia
        };

        if (this.facturacao.pagamento.linha_pagamentos.length >= 1) {
          for (let index = 0; index < this.facturacao.pagamento.linha_pagamentos.length; index++) {
            const l = this.facturacao.pagamento.linha_pagamentos[index];
            if (l.id == linha.id) {
              this.facturacao.pagamento.linha_pagamentos.splice(index, 1);
              this.facturacao.pagamento.linha_pagamentos.splice(index, 0, linha);
              validar = 1;
            }
          }
          if (validar == 0) {
            this.facturacao.pagamento.linha_pagamentos.push(linha);
          }
        } else {
          this.facturacao.pagamento.linha_pagamentos.push(linha);
        }

        this.calcularTroco();
        this.facturacao.pagamento.forma = null;
        this.facturacao.pagamento.valor_recebido = 0.0;
        this.facturacao.pagamento.referencia = null;
        this.facturacao.pagamento.data_pagamento = null;
        this.facturacao.pagamento.forma_pagamento_id = null;
      }
    }
  }


  private calcularTroco() {
    this.facturacao.pagamento.troco = 0;
    var valor = 0;
    this.facturacao.pagamento.linha_pagamentos.forEach(element => {
      valor += element.valor_entrada;

    });
    if (valor > 0) {
      this.facturacao.pagamento.troco = valor - this.facturacao.total;
      if (valor <= this.facturacao.total) {
        this.facturacao.pagamento.troco = 0;
      }
    } else {
      this.facturacao.pagamento.troco = 0;
    }
    this.facturacao.pagamento.total_valor_recebido = valor;
  }

  deleteRowFormaPagamento(linha: any) {
    for (let i = 0; i < this.facturacao.pagamento.linha_pagamentos.length; ++i) {
      if (this.facturacao.pagamento.linha_pagamentos[i].id === linha.id) {
        if (this.facturacao.pagamento.linha_pagamentos[i].designacao !== 'Adiantamento') {
          this.facturacao.pagamento.adiantamento = []
        }
        this.facturacao.pagamento.linha_pagamentos.splice(i, 1);
        this.facturacao.pagamento.total_valor_recebido -= linha.valor_entrada;
        this.facturacao.pagamento.troco = (this.facturacao.pagamento.total_valor_recebido < this.facturacao.total || this.facturacao.pagamento.total_valor_recebido == 0 ? 0 : this.facturacao.pagamento.total_valor_recebido - this.facturacao.total);



        this.calcularTotal();
      }
    }
  }
}