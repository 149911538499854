import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/providers/http/http.service';

interface IServerResponse {
  items: string[];
  total: number;
}

@Component({
  selector: 'app-test-pagination',
  templateUrl: './test-pagination.component.html',
  styleUrls: ['./test-pagination.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class TestPaginationComponent implements OnInit {

   facturas: any[] = [];

  constructor(private http: HttpService) { }

  ngOnInit() {


    this.getPageFilterData(1);

  }
  getPageFilterData(page: number) {    
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarFacturacao();
  }
  /**
     * @name "Listar facturação"
     * @descriptio "Esta Função permite Listar todas facturações"
     * @author "caniggia.moreira@itgest.pt"
     * @param start
     * @param end
     */
  private listarFacturacao() {

    //this.configService.loaddinStarter('start'); 
    this.http.__call('factura/list', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.facturas = Object(response).data.data;
        console.log(this.facturas);
        //this.configService.loaddinStarter('stop');

      }
    );
  }


}

/*  @Input('data') meals: string[] = [];
  asyncMeals: Observable<string[]>;
  p: number = 1;
  total: number;
  loading: boolean;

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.loading = true;
    this.asyncMeals = serverCall(this.meals, page, this.http).pipe(
      tap(res => {
        console.log(res);
        this.total = res.total;
        this.p = page;
        this.loading = false;
      }),
      map(res => res.items)
    );
  }
}*/

/**
 * Simulate an async HTTP call with a delayed observable.
 *
function serverCall(meals: string[], page: number, http: HttpService): Observable<IServerResponse> {
  const perPage = 4;
  const start = (page - 1) * perPage;
  const end = start + perPage;

  var pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "facturas.created_at",
    searchData: ''
  };

  http.__call('factura/list', pagination).subscribe(
    response => {
      pagination.lastPage = Object(response).data.lastPage;
      pagination.page = Object(response).data.page;
      pagination.total = Object(response).data.total;
      pagination.perPage = Object(response).data.perPage;

      meals = Object(response).data.data;
      console.log(Object(response).data.data);
    }
  );

  return of({
    items: meals.slice(start, end),
    total: pagination.total
  }).pipe(delay(1000));

}*/
