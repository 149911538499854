
import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'createOrEditImposto',
  templateUrl: './create-or-edit-imposto.component.html',
  styleUrls: ['./create-or-edit-imposto.component.css']
})
export class CreateOrEditImpostoComponent implements OnInit {
  @Input() modal: string = "modalImpostoCreateOrEdit";
  @Input() title: string = "Registar Imposto";
  @Input() imposto:any;
  public exemptionReasons: any;
  private codes: any = [
    {
      slug: 'ISE',
      description: 'Isento'
    },
    {
      slug: 'NOR',
      description: 'Normal'
    }
  ];


  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormImposto: FormGroup;

  @Output() private loadListImposto = new EventEmitter<any>();

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.isentos()
  }

  createForm() {
    this.simpleFormImposto = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      codigo: [null, Validators.required],
      exemptionReasonCode: [null],
      valor: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      descricao: [null, Validators.required],
      activo: [null, Validators.required],
      has_sales_documents: [0],
      isento_id : [null]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormImposto.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormImposto.reset();
  }

  onSubmit() {
    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormImposto.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormImposto.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'imposto/create' : 'imposto/update/' + index);

    this.createOrEdit(uri, this.simpleFormImposto, (index === null ? true : false));
  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset();
          this.loadListImpostos(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListImpostos(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.imposto !== undefined ) {
      this.title = "Editar Imposto";
      this.simpleFormImposto.patchValue({
        index: this.imposto.id,
        codigo: this.imposto.codigo,
        exemptionReasonCode: this.imposto.exemption_reason_code,
        isento_id: this.imposto.isento_id,
        descricao: this.imposto.descricao,
        valor: this.imposto.valor,
        activo: this.imposto.activo,
        has_sales_documents: this.imposto.has_sales_documents,
      });
    } else {
      this.title = "Registar Imposto";
    }
  }

  public loadListImpostos(imposto) {
    this.loadListImposto.emit(imposto);
  }

  addValidator(value: string) {
    if (value === 'ISE') {
      this.simpleFormImposto.get('isento_id').setValidators(Validators.required);
      this.simpleFormImposto.get('valor').setValue(0);
    } else {
      this.simpleFormImposto.get('isento_id').clearValidators();
    }

    this.simpleFormImposto.get('isento_id').updateValueAndValidity();
  }

  public isentos() {
    this.configService.loaddinStarter('start');
    this.http.call_get('isentos/getall', null).subscribe(
      response => {
        this.exemptionReasons =  response ; 
        console.log(this.exemptionReasons)
        this.configService.loaddinStarter('stop');
      }
    );
  }
}
