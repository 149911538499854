
import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-isento',
  templateUrl: './create-or-edit-isento.component.html',
  styleUrls: ['./create-or-edit-isento.component.css']
})
export class CreateOrEditIsentoComponent implements OnInit {
  @Input() modal: string = "modalIsentotoCreateOrEdit";
  @Input() title: string = "Registar Imposto";
  @Input() imposto:any;

  private codes: any = [
    {
      slug: 'ISE',
      description: 'Isento'
    },
    {
      slug: 'RED',
      description: 'Reduzida'
    },
    {
      slug: 'INT',
      description: 'Intermédia'
    },
    {
      slug: 'NOR',
      description: 'Normal'
    }
  ];

  private exemptionReasons: any = [
    {
      slug: 'M01',
      description: 'Artigo 16.º n.º 6 do CIVA'
    },
    {
      slug: 'M02',
      description: 'Artigo 6.º do Decreto-Lei n.º 198/90, de 19 de Junho'
    },
    {
      slug: 'M03',
      description: 'Exigibilidade de caixa'
    },
    {
      slug: 'M04',
      description: 'Isento Artigo 13.º do CIVA'
    },
    {
      slug: 'M05',
      description: 'Isento Artigo 14.º do CIVA'
    },
    {
      slug: 'M06',
      description: 'Isento Artigo 15.º do CIVA'
    },
    {
      slug: 'M07',
      description: 'Isento Artigo 9.º do CIVA'
    },
    {
      slug: 'M08',
      description: 'IVA – Autoliquidação'
    },
    {
      slug: 'M09',
      description: 'IVA – Não confere direito a dedução'
    },
    {
      slug: 'M10',
      description: 'IVA – Regime de isenção'
    },
    {
      slug: 'M11',
      description: 'Regime particular do tabaco'
    },
    {
      slug: 'M12',
      description: 'Regime da margem de lucro – Agências de viagens'
    },
    {
      slug: 'M13',
      description: 'Regime da margem de lucro – Bens em segunda mão'
    },
    {
      slug: 'M14',
      description: 'Regime da margem de lucro – Objectos de arte'
    },
    {
      slug: 'M15',
      description: 'Regime da margem de lucro – Objectos de colecção e antiguidades'
    },
    {
      slug: 'M16',
      description: 'Isento Artigo 14.º do RITI'
    },
    {
      slug: 'M20',
      description: 'IVA – Regime forfetário'
    },
    {
      slug: 'M99',
      description: 'Não sujeito; não tributado'
    }
  ];

  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormImposto: FormGroup;

  @Output() private loadListImposto = new EventEmitter<any>();

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.simpleFormImposto = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      reason_code: [null, Validators.required],
      reason_description: [null, Validators.required],
      estado: [null, Validators.required],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormImposto.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormImposto.reset();
  }

  onSubmit() {
    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormImposto.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormImposto.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'isentos/create' : 'isentos/update/' + index);

    this.createOrEdit(uri, this.simpleFormImposto, (index === null ? true : false));
  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset();
          this.loadListImpostos(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListImpostos(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.imposto !== undefined ) {
      console.log(this.imposto);
      this.title = "Editar Isento";
      this.simpleFormImposto.patchValue({
        index: this.imposto.id,
        reason_code: this.imposto.reason_code,
        reason_description: this.imposto.reason_description,
        estado: this.imposto.estado
      });
    } else {
      this.title = "Registar Isento";
    }
  }

  public loadListImpostos(imposto) {
    this.loadListImposto.emit(imposto);
  }


  addValidator(value: string) {
    if (value === 'ISE') {
      this.simpleFormImposto.get('exemptionReasonCode').setValidators(Validators.required);
      this.simpleFormImposto.get('valor').setValue(0);
    } else {
      this.simpleFormImposto.get('exemptionReasonCode').clearValidators();
    }

    this.simpleFormImposto.get('exemptionReasonCode').updateValueAndValidity();
  }
}
