import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

declare var require: any;

@Component({
  selector: 'app-saft',
  templateUrl: './saft.component.html',
  styleUrls: ['./saft.component.css']
})
export class SaftComponent implements OnInit {
  private data = {
    ano: null,
    de: null,
    para: null
  };

  private anos: any = [];
  private saftWithTodayDay: boolean = false;
  private saft = null;
  fileUrl;
  constructor(private http: HttpService, private configService: ConfigService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.gerarAno();

    //const data = 'some text';
    //const blob = new Blob([data], { type: 'application/octet-stream' });

    //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

    this.getPageFilterData(1);
    console.log(this.mesPorExtenso('2019', '02'))


  }
  private mesPorExtenso(ano, mes) {
    var CurrentDate = new Date(ano + '-' + mes + '-01');
    return moment(CurrentDate).locale('pt-br').format('MMMM')
  }
  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = 2019; i <= anyo; i++) {
      this.anos[j] = i;
      j++;
    }
  }
  private message: number;

  private btnSaft() {
    this.saftWithTodayDay = false;

    if (this.data.ano == null || this.data.ano == "null") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio.", 'alert-danger', true);
      return;
    }

    if(parseInt(this.data.de) > parseInt(this.data.para) ) {
      this.configService.showAlert("O mês de inicio não pode ser superior ao mês de fim", 'alert-danger', true);
      return;
    } 

    if (this.data.ano > new Date().getFullYear()) {
      this.configService.showAlert("O ano selecionado não pode ser superior ao ano corrente", 'alert-danger', true);
      return;
    }

     if(this.data.ano == new Date().getFullYear() ){
      if(parseInt(this.data.de) > (new Date().getMonth() + 1) || parseInt(this.data.para) > (new Date().getMonth() + 1)) {
        this.configService.showAlert("Os meses selecionados não podem ser superiores ao mês corrente", 'alert-danger', true);
        return;
      }

      if(parseInt(this.data.de) == (new Date().getMonth() + 1) || parseInt(this.data.para) == (new Date().getMonth() + 1)) {
        this.saftWithTodayDay = true;
      }
    } 

    this.data.de = '01';

    if (this.data.ano == new Date().getFullYear()) {
        this.data.para = (new Date().getMonth() + 1) + '';

        if (this.data.para.length < 2) {
            this.data.para = '0' + this.data.para;
        }
    } else {
        this.data.para = '11';
    }

    this.configService.loaddinStarter('start');
    
    let parameters = {
      year: this.data.ano,
      month_from: this.data.de,
      month_to: this.data.para
    }

    this.http.__call('saftAo', parameters).subscribe(
      response => {
        if (Object(response).code === 200) {
          this.configService.showAlert(Object(response).message, 'alert-success', true);
        } else {
          this.configService.showAlert(Object(response).message, 'alert-danger', true);
        }
        this.configService.loaddinStarter('stop');
      }
    );
  }


  downloadSaftXml(anexo) {
    const urlServer = 'saftAo/getstream/' + anexo.id;
    return this.http._getFileFromServer(urlServer)
      .subscribe(
        (res) => {
          //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(res);
          link.download = anexo.nome_saft /*+ ".xml"*/;
          link.click();
        },
        error => {

        });
  }



  private safts = []
  loading = false

  private listarSafts() {

    this.loading = true

    this.http.__call('safts', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.safts = Object(response).data.data;
        this.loading = false

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarSafts();
  }

}

