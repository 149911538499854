import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//  Importar o módulo da biblioteca
import { NgxSpinnerModule } from 'ngx-spinner'; 

import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErroInterceptor } from './interceptors/erro-interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ArchwizardModule } from 'angular-archwizard';
import { Select2Module } from 'ng2-select2';

import { AppRoutingModule, routeComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AsidenavbarComponent } from './components/layout/asidenavbar/asidenavbar.component';
import { TopnavbarComponent } from './components/layout/topnavbar/topnavbar.component';
import { FooternavbarComponent } from './components/layout/footernavbar/footernavbar.component';
import { SetingsnavbarComponent } from './components/layout/setingsnavbar/setingsnavbar.component';
import { FornecedorComponent } from './components/fornecedor/fornecedor.component';
import { UtilizadorComponent } from './components/utilizador/utilizador.component';
import { ListarProdutosComponent } from './components/listar-produtos/listar-produtos.component';
import { ImpostoComponent } from './components/imposto/imposto.component';
import { from } from 'rxjs';
import { DocumentoComponent } from './components/documento/documento.component';
import { SerieComponent } from './components/serie/serie.component';
import { ListarFacturacaoComponent } from './components/listar-facturacao/listar-facturacao.component';

import { LoginComponent } from './components/autenticacao/login/login.component';
import { ReciboComponent } from './components/recibo/recibo.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AlertComponent } from './components/alert/alert.component';
import { SaftComponent } from './components/saft/saft.component';

// providers imports
import { AuthService } from './providers/auth/auth.service';
import { HttpService } from './providers/http/http.service';
import { ConfigService } from './providers/config/config.service';
import { PDFComponent } from './components/pdf/pdf.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { StockMovimentoComponent } from './components/stock-movimento/stock-movimento.component';
import { ContaCorrenteComponent } from './components/conta-corrente/conta-corrente.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { RoleComponent } from './components/role/role.component';
import { ModulosComponent } from './components/modulos/modulos.component';

import { ProgressBarModule } from "angular-progress-bar";
//import { HomeComponent } from './components/home/home.component';
import { RegisterComponent } from './components/register/register.component';


import { SummaryComponent } from './components/board/card/summary/summary.component';
import { DetailComponent } from './components/board/card/detail/detail.component';
import { BoardComponent } from './components/board/board/board.component';
import { HeaderComponent } from './components/common/header/header.component';
import { ListComponent } from './components/board/list/list.component';

import { ContentEditDirective } from './directives/common/content-edit.directive';
import { LocalService } from './providers/board/local/local.service';
import { TarefaComponent } from './components/tarefa/tarefa.component';
import { BiComponent } from './bi/bi.component';

import { ContextMenuComponent } from './components/common/contextmenu/context-menu.component';
import { AprovisionamentoComponent } from './components/aprovisionamento/aprovisionamento.component';
import { ProdutoFornecedorComponent } from './components/produto-fornecedor/produto-fornecedor.component';
import { MoedaComponent } from './components/moeda/moeda.component';
import { BancoComponent } from './components/banco/banco.component';
import { ProjectoComponent } from './components/projecto/projecto.component';


import { P404Component } from './components/error/404.component';
import { P500Component } from './components/error/500.component';
import { P403Component } from './components/error/403.component';
import { TarifarioComponent } from './components/tarifario/tarifario.component';
import { ContratoComponent } from './components/contrato/contrato.component';
import { FacturacaoChargeComponent } from './components/facturacao-charge/facturacao-charge.component';
import { PlanoPrecoComponent } from './components/plano-preco/plano-preco.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { TestPaginationComponent } from './components/test-pagination/test-pagination.component';
import { MovimentoCaixaComponent } from './components/caixa/movimento-caixa/movimento-caixa.component';
import { LojaComponent } from './components/loja/loja.component';

import { RedefinirPasswordComponent } from './components/utilizador/redefinir-password/redefinir-password.component';
 import { UtilizadorAlterarPasswordComponent } from './components/utilizador/utilizador-alterar-password/utilizador-alterar-password.component';
import { ConsultarReciboComponent } from './components/recibo/consultar-recibo/consultar-recibo.component';
import { PaisComponent } from './components/pais/pais.component';
import { TipoClienteComponent } from './components/terceiro/tipo-cliente/tipo-cliente.component';
import { TipoIdentidadeComponent } from './components/terceiro/tipo-identidade/tipo-identidade.component';
import { RedefinirPasswordFirstAcessComponent } from './components/utilizador/redefinir-password-first-acess/redefinir-password-first-acess.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { EmitirNotaCreditoComponent } from './components/facturacao/emitir-nota-credito/emitir-nota-credito.component';
import { CreateOrEditProdutoComponent } from './components/listar-produtos/create-or-edit-produto/create-or-edit-produto.component';
import { CreateOrEditUtilizadorComponent } from './components/utilizador/create-or-edit-utilizador/create-or-edit-utilizador.component';
import { ExcelService } from 'src/app/services/excel.service';
import { EditarComponent } from './components/terceiro/create-or-edit-client/editar/editar.component';
import {CreateOrEditClientComponent} from "./components/terceiro/create-or-edit-client/create-or-edit-client.component"
import {CreateOrEditImpostoComponent} from "./components/imposto/create-or-edit-imposto/create-or-edit-imposto.component";
import { CKEditorModule } from 'ngx-ckeditor';
import { CreateOrEditSerieComponent } from './components/serie/create-or-edit-serie/create-or-edit-serie.component';
import { IsentoComponent } from './components/isento/isento.component';
import { CreateOrEditIsentoComponent } from './components/isento/create-or-edit-isento/create-or-edit-isento.component'

@NgModule({
  declarations: [
    CreateOrEditImpostoComponent,
    CreateOrEditUtilizadorComponent,
    CreateOrEditProdutoComponent,
    RedefinirPasswordFirstAcessComponent,
    RedefinirPasswordComponent,
    UtilizadorAlterarPasswordComponent,
    AppComponent,
    routeComponents,
    LayoutComponent,
    AsidenavbarComponent,
    TopnavbarComponent,
    FooternavbarComponent,
    SetingsnavbarComponent,
    FornecedorComponent,
    UtilizadorComponent,
    ListarProdutosComponent,
    ImpostoComponent,
    DocumentoComponent,
    SerieComponent,
    ListarFacturacaoComponent,
    AlertComponent,
    ReciboComponent,
    LoadingComponent,
    SaftComponent,
    EmpresaComponent,
    StockMovimentoComponent,
    ContaCorrenteComponent,
    PermissionsComponent,
    RoleComponent,
    ModulosComponent,

    //HomeComponent,
    LoginComponent,
    RegisterComponent,

    SummaryComponent,
    DetailComponent,
    BoardComponent,
    HeaderComponent,
    ListComponent,
    ContentEditDirective,
    ContextMenuComponent,
    AprovisionamentoComponent,
    ProdutoFornecedorComponent,
    MoedaComponent,
    BancoComponent,
    ProjectoComponent,

    P404Component,
    P500Component,
    P403Component,
    TarifarioComponent,
    ContratoComponent,
    FacturacaoChargeComponent,
    PlanoPrecoComponent,
    TestPaginationComponent,
    MovimentoCaixaComponent,
    LojaComponent,
    ConsultarReciboComponent,
    PaisComponent,
    TipoClienteComponent,
    TipoIdentidadeComponent,
    EmitirNotaCreditoComponent,
    EditarComponent,
    CreateOrEditClientComponent,
    CreateOrEditSerieComponent,
    IsentoComponent,
    CreateOrEditIsentoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    CKEditorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ArchwizardModule,
    Select2Module,
    NgxSpinnerModule,
    ReactiveFormsModule, 
    NgxPaginationModule,
    NgxLoadingModule.forRoot({ backdropBorderRadius: '3px', backdropBackgroundColour: 'rgba(255, 255, 255, 0.78)', primaryColour: '#20a8d8', secondaryColour: '#20a8d8', tertiaryColour: '#20a8d8' }),
    ProgressBarModule,
  ],
  exports: [
    LayoutComponent,
    AsidenavbarComponent,
    TopnavbarComponent,
    FooternavbarComponent,
    SetingsnavbarComponent,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErroInterceptor, multi: true },
    HttpService,
    AuthService,
    ConfigService,
    LocalService,
    ExcelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
