
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service'; 
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {

  constructor(private http: HttpService, private configService: ConfigService) { }


  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;
  private permissions = {
    id: null,
    name: null,
    slug: null,
    description: null
  }
  private items: any = [];
  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "permissions.module_id"
  };

  private modulos: any = [];
  
  
  ngOnInit() {

    this.getPageFilterData(1);
    //this.listarpermissionss(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarpermissionss();
  }

  private listarpermissionss() {

    this.configService.loaddinStarter('start');

    this.http.__call('permission/listar', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarpermissionss();

  }

  private pages = [];
  private nextProvPageNumber = 1;



  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start'); 
    if (this.permissions.slug == null || this.permissions.name == null || this.permissions.description == null) {
      this.configService.showAlert("Todos os campos são de caracter obrigatório", 'alert-danger', true);
    } else {
      this.http.__call('permission/registar', this.permissions).subscribe(
        res => {

          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.listarpermissionss();
            this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.iniPermission();
            this.configService.loaddinStarter('stop');
          }


        }
      )
    };
  }

  /**
   * @name "Editar permissions"
   * @description "Esta Função permite Registar permissions"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.permissions.name == "" && this.permissions.description == "") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);

    } else {
      this.http.__call('permission/update/' + this.permissions.id, this.permissions).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarpermissionss();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private refresh(id) {
    this.items.forEach(element => {
      if (element.id == id) {
        this.permissions.id = element.id;
        this.permissions.name = element.name;
        this.permissions.description = element.description;
        this.permissions.slug = element.slug;
      }
    });
    this.carregarOptions()
  }

  private carregarOptions() { 
    this.modulosOption();
  }

  private modulosOption() {

    this.http.call_get('modulo/select-option', null).subscribe(
      response => {
        this.modulos = Object(response).data;
      }
    );
  }


  private iniPermission() {
    this.permissions = {
      id: null,
      name: null,
      slug: null,
      description: null
    }
  }

} 
