import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { AuthService } from '../providers/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private autheticationService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const utilizaorCorrente = this.autheticationService.currentUserValue;
    if (utilizaorCorrente) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${utilizaorCorrente.token.token}`,
          Accept: 'application/json'
        }
      });

    }
    return next.handle(req);
  }
}
