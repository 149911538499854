import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facturacao-charge',
  templateUrl: './facturacao-charge.component.html',
  styleUrls: ['./facturacao-charge.component.css']
})
export class FacturacaoChargeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
