import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.css']
})
export class PagamentoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "created_at",
    searchData: ''
  };
  private forma_pagamento = {
    id:null,
    designacao: null,
    descricao: null
  }
  private formas: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.listarFormaPagamentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }



  /**
  * @name "Listar facturação"
  * @descriptio "Esta Função permite Listar todas facturações"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private listarFormaPagamentos(start, end, search, searchData, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.order = orderBy;
    this.pagination.searchData = searchData;
    this.http.__call('formaPagamento/listar', this.pagination).subscribe(
      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.formas = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private searchFormaPagamentos(e) {
    this.pagination.page = 1;
    this.listarFormaPagamentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarFormaPagamentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarFormaPagamentos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarFormaPagamentos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.listarFormaPagamentos(page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }


  /**
    * @name "Registar series"
    * @description "Esta Função permite Registar series"
    * @author "caniggia.moreira@itgest.pt"
    * @param start 
    * @param end 
    */
  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    this.http.__call('formaPagamento/register', this.forma_pagamento).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
           
          this.listarFormaPagamentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
          this.configService.loaddinStarter('stop');
        }
      }
    )
      ;
  }

}
