import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '../providers/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable()
export class ErroInterceptor implements HttpInterceptor {

  constructor(public toasterService: ToastrService, private authenticationService: AuthService, private router: Router, private configService: ConfigService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(evt => {
      if (evt instanceof HttpResponse) {
        if (evt.body.code === 201) {
          this.toasterService.info(evt.body.message, 'Info!');
        }else if (evt.body.message) { 
          this.toasterService.success(evt.body.message, 'Sucesso!');
        }
      }
    }), catchError((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        try {
          if (!navigator.onLine) {
            this.configService.loaddinStarter('stop');
            this.toasterService.info('Sem conexão à internet', 'Erro de Conexão');
          }
          if (err.status === 0) {
            this.configService.loaddinStarter('stop');
            this.toasterService.error('Dificuldades em estabelecer conexão com o servidor.', 'Erro de Conexão');
          } else if (err.status === 401) {
            // se retornar erro 401
            this.authenticationService.logout();
            this.toasterService.warning('Seu Token expirou. Volte autenticar-se novamente', 'Token Expirado');
          } else if (err.status === 403) {
            this.router.navigate(['/403']);
          } else if (err.status === 500) {
            this.configService.loaddinStarter('stop');
            this.toasterService.error('Contacte o Administrador', 'Erro Interno');
          }else if (err.status === 404) {
            let mensagem_erro;
            if (err.error instanceof Array) {
              mensagem_erro = err.error[0].message;
            } else {
              mensagem_erro = err.error.message;
            }
            this.toasterService.warning(mensagem_erro, err.error.title);
            
          }else if (err.status === 302) {
            let mensagem_erro;
            if (err.error instanceof Array) {
              mensagem_erro = err.error[0].message;
            } else {
              mensagem_erro = err.error.message;
            }
            this.toasterService.warning(mensagem_erro, err.error.title);
            
          } else {
            let erro_mensagem;
            if (err.error instanceof Array) {
              erro_mensagem = err.error[0].message;
            } else {
              erro_mensagem = err.error.message;
            }
            this.toasterService.error(erro_mensagem, err.error.title);
          }

        } catch (e) {
          this.configService.loaddinStarter('stop');
          this.toasterService.error('Erro desconhecido', 'Contacte o Administrador');
        }
      }
      return throwError(err);
    }));
  }
}
