import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class EditarComponent implements OnInit {
  @Input() modalEditarCliente: string = "modalEditarCliente";

  constructor() { }

  ngOnInit() {
  }

}
