import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ReportUtilizadorService } from 'src/app/components/Relatorios/report-utilizador.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';




@Component({
  selector: 'app-utilizador',
  templateUrl: './utilizador.component.html',
  styleUrls: ['./utilizador.component.css']
})
export class UtilizadorComponent implements OnInit {

  public utilizador: any;
  private loading: boolean = false;
  public empresa: any = [];

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService,private excelService: ExcelService, private ReportUtilizador:ReportUtilizadorService) { }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Utilizadores -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.ReportUtilizador.relatorioUtilizadores(this.items,'save',this.dados_empresa, file)
  }

  imprimirPDF(): void {
  var file =  document.getElementsByClassName("exportPDFCliente")[0]
   /* var doc = new jsPDF('l', '', 'a4')
    doc.text(150, 70, 'Listagem de Utilizadores', 'center')
    
    doc.autoTable({ html: file})
    doc.autoPrint();
    doc.output("dataurlnewwindow");*/
    this.ReportUtilizador.relatorioUtilizadores(this.items,'imprimir',this.dados_empresa,file)
  }
  
  private dados_empresa: any = [];
  private items: any = [];
  private lojas: any = [];
  private roles: any = [];
  private empresas: any = [];


  ngOnInit() {
    this.getPageFilterData(1);
    this.user_empresa();

  }


  private listarUsers() {

    this.configService.loaddinStarter('start');

    this.loading = true
    this.http.__call('user/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }
 
  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarUsers();
  }


  public user_empresa() {
    this.http.call_get('empresa/empresa-user/',null).subscribe(
      response => {
        this.dados_empresa = Object(response).data;
      }
    );
  }
  
  private setDataUtilizador(utilizador) {
    this.utilizador = utilizador
  }
 


} 