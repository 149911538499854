import { Component, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UtilizadorFormValidationService } from '../utilizador-form-validation.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

/** passwords must match - custom validator */
export const password_confirmationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirm = control.get('password_confirmation');
  return password && confirm && password.value === confirm.value ? null : { 'passwordMismatch': true };
};

@Component({
  selector: 'app-redefinir-password',
  templateUrl: './redefinir-password.component.html',
  styleUrls: ['./redefinir-password.component.css',
  ],
  providers: [UtilizadorFormValidationService],
  encapsulation: ViewEncapsulation.None
})
export class RedefinirPasswordComponent {

  @Input() user: any;

  simpleForm: FormGroup;
  submitted = false;
  formErrors: any;
  loading = false;

  private password_generator: string;


  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    public vf: UtilizadorFormValidationService,
    private _route: Router
  ) {

    this.formErrors = this.vf.errorMessages;
    this.createForm();
  }

  gerarPassword() {
    const RandExp = require('randexp');
    const randexp = new RandExp(/[A-Z][0-9][!@#\$%\^&\*][a-z][A-Z][0-9]{8}/);
    //new RandExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}){8}/);
    randexp.defaultRange.add(0, 65535);
    this.password_generator = randexp.gen();
    this.simpleForm.patchValue({
      password: this.password_generator
    });

  }



  createForm() {
    this.simpleForm = this.fb.group({
      user_id: [''],
      password: ['',
        [Validators.required,
        Validators.minLength(this.vf.formRegras.passwordMin),
        Validators.pattern(this.vf.formRegras.passwordPattern)
        ]],
      password_confirmation: ['', [Validators.required]],

    }, { validator: password_confirmationValidator }
    );
  }





  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {
     
    this.submitted = false;
    this.simpleForm.reset();

  }

  /**
   * redefinirPassword
   */
  onSubmit() {


    this.submitted = true;
    // stop here if form is invalid
    if (this.simpleForm.invalid) {
      return;
    }
    this.loading = true;

    this.simpleForm.patchValue({
      user_id: this.user.id
    });

    this.http.__call('redefinir-password', this.simpleForm.value).subscribe(
      res => {
        this.submitted = false;
        this.loading = false;
        this.simpleForm.reset();
      });
  }


}
