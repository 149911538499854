import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportClienteService {

  constructor(private configService: ConfigService) { }

  
  

  public relatorioClientes(relatorio: any, p = 'print', empresa: any, file) {

    var doc = new jsPDF('l', '', 'a4')

    const totalPagesExp = "{total_pages_count_string}";

    for(var i= 0; i< empresa.length; i++){
      doc.text(150, 60,empresa[i].companyName ,'center')
      doc.addImage(empresa[i].logotipo, 'JPEG',130, 17,empresa[i].width,empresa[i].height)
      }
        doc.setFontType('bold')
        doc.setFontSize(15)
       // doc.text(145, 60, 'Relatório', 'center')
        doc.setFontSize(15)
        doc.text(150, 70, 'Listagem de Clientes', 'center')

  doc.autoTable({ html: file ,addPageContent: data => {
    let footerStr = "Página " + doc.internal.getNumberOfPages();
    if (typeof doc.putTotalPages === 'function') {
      footerStr = footerStr + " de " + totalPagesExp;
    }
    doc.setFontSize(10);
    doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
  },
    styles: { textColor: [0, 0, 0] },
    columnStyles: {
    0: {cellWidth: 20,halign: 'center',},
    1: {cellWidth: 20,halign: 'center'},
    2: {cellWidth: 20,halign: 'center'},
    3: {cellWidth: 20,halign: 'center'},
    4: {cellWidth: 20,halign: 'center'},
    5: {cellWidth: 20,halign: 'center'},
    6: {cellWidth: 20,halign: 'center'},
    7: {cellWidth: 20,halign: 'center'},
    8: {cellWidth: 20,halign: 'center'}},
    rowsStyles:{},
  startY: 80,
  theme: 'grid',
  
  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }
  
    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

   

  }
}
