import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.css']
})
export class PaisComponent implements OnInit {


  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;


  private pais = {
    id: null,
    nome: null
  }

  private provincia = {
    id: null,
    nome: null,
    pais_id: null
  }

  private municipio = {
    id: null,
    nome: null,
    provincia_id: null
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "created_at"
  };


  private items: any = [];
  private paises: any = [];
  private municipios: any = [];
  private provincias: any = [];

  constructor(private http: HttpService, private configService: ConfigService) {

  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listPais();

  }



  private listPais() {

    this.configService.loaddinStarter('start');

    this.http.__call('pais/listaDataTable', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private listarPais() {

    this.http.call_get('select-box/listar/pais', null).subscribe(
      response => {
        this.paises = Object(response).data;
      }
    );
  }

  private selectProvincias(id: number) {
    this.http.call_get('select-option/provinciasByPais/' + id, null).subscribe(
      response => {

        this.provincias = Object(response).data;

      }
    );
  }


  private selectMunicipios(id: number) {
    this.http.call_get('select-option/municipioByProvincia/' + id, null).subscribe(
      response => {
        // console.log(Object(response).data);
        this.municipios = Object(response).data;
        //console.log(Object(response).data);
      }
    );
  }


  private listarProvinciasPaises(pais: any) {
    
    this.pais.id = pais.id;
    this.pais.nome = pais.nome;
    this.provincia.pais_id = pais.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select-option/provinciasByPais/' + this.pais.id, null).subscribe(
      response => {
        this.provincias = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarMunicipiosProvince(provincia: any) {
    
    this.provincia.id = provincia.id;
    this.provincia.nome = provincia.nome;
    this.municipio.provincia_id = provincia.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select-option/municipioByProvincia/' + this.provincia.id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }


  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (this.pais.nome == null || this.pais.nome == null) {

      this.configService.showAlert('É obrigatório fornecer o nome', 'alert-danger', true);

    } else {
      this.http.__call('pais/register', this.pais).subscribe(
        data => {
          if (Object(data).code == 500) {
            this.configService.showAlert(Object(data).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            this.listPais();
            this.configService.clearFormInputs(e);
          }

        }
      );
    }
    this.configService.loaddinStarter('stop');
  }



  private refreshDataCliente(id, nome) {
    this.pais.id = id;
    this.pais.nome = nome;


  }

  private editar(e) {
    e.preventDefault();
   //console.log(this.cliente)
   this.configService.loaddinStarter('start');
    if (this.pais.nome == "" || this.pais.nome == null) {
      this.configService.showAlert('É obrigatório fornecer o nome', "alert-danger", true);
    } else {
      this.http.__call('pais/update/' + this.pais.id, this.pais).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listPais();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
    
  }
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listPais();

  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listPais();
  }

  private registerProvincia() {

    if (this.provincia.nome == null) {
      this.configService.showAlert('Digite o nome da Provínica', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('pais/provincia/register', this.provincia).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.provincia.nome = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarProvinciasPaises({ nome: this.pais.nome, id: this.pais.id });

          }
        }
      );
    }
    
  }

  private clearPais(){
    this.pais.nome = null;
    
  }

  private clearProvincia(){
    this.provincia.nome = null;
    
  }

  private registerMunicipio() {

    if (this.municipio.nome == null) {
      this.configService.showAlert('Digite o nome do concelho', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('provincia/municipio/register', this.municipio).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.municipio.nome = null;
            this.configService.loaddinStarter('stop');
            this.listarMunicipiosProvince({ nome: this.provincia.nome, id: this.provincia.id });

          }
        }
      );
    }
    
  }


}
