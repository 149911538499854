import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service'; 
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-asidenavbar',
  templateUrl: './asidenavbar.component.html',
  styleUrls: ['./asidenavbar.component.css']
})
export class AsidenavbarComponent { 
  public currentUser: any; 
  constructor( private auth: AuthService, private configService: ConfigService) {
    this.currentUser = this.auth.currentUserValue;
  }
 
  canActivateRouterLink(permission: string): boolean {
    return this.auth.canActivateRouterLink(permission); 
  }

  
}
