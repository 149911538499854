import { Component, OnInit, ViewChild } from '@angular/core'; 
import { HttpService } from 'src/app/providers/http/http.service'; 
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.css']
})
export class ModulosComponent implements OnInit {

  constructor(private http: HttpService, private configService: ConfigService) { }


  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;
  private modulo = {
    id: null,
    nome: null,
    descricao: null
  }
  private items: any = [];
  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "created_at"
  };

  ngOnInit() {
    this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }



  private listarmodulos(start, end, search, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.order = orderBy;

    this.http.__call('modulo/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }

  private pesquisar(e) {
    this.pagination.page = 1;
    this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }



  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    this.modulo.nome = e.target.elements[0].value;
    this.modulo.descricao = e.target.elements[1].value;

    if (this.modulo.nome == "" && this.modulo.descricao == "") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('modulo/registar', this.modulo).subscribe(
        res => {
          this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
          this.configService.clearFormInputs(e);
          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        }
      );
    }
  }

  /**
   * @name "Editar Modulo"
   * @description "Esta Função permite Registar modulo"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.modulo.nome == "" && this.modulo.descricao == "") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);

    } else {
      this.http.__call('modulo/update/' + this.modulo.id, this.modulo).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarmodulos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
            
          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private refresh(id) {
    this.items.forEach(element => {
      if(element.id == id){
        this.modulo.id = element.id;
        this.modulo.nome = element.nome;
        this.modulo.descricao = element.descricao;
      }
    });
  }

} 
