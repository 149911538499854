import { Component,ViewEncapsulation, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'; 
import { first } from 'rxjs/operators';
import { UtilizadorComponent } from '../utilizador.component';
import { UtilizadorFormValidationService } from '../utilizador-form-validation.service';


/** passwords must match - custom validator */
export const password_confirmationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirm = control.get('password_confirmation');
  return password && confirm && password.value === confirm.value ? null : { 'passwordMismatch': true };
};

@Component({
  selector: 'createOrEditutilizador',
  templateUrl: './create-or-edit-utilizador.component.html',
  styleUrls: ['./create-or-edit-utilizador.component.css'],
  providers: [UtilizadorFormValidationService],
  encapsulation: ViewEncapsulation.None
})
export class CreateOrEditUtilizadorComponent implements OnInit {

  @Input() modal: string = "modalutilizadorCreateOrEdit";
  @Input() title: string = "Registar utilizador";
  @Input() utilizador:any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() simpleFormutilizador: FormGroup; 

  @Output() private loadListutilizador = new EventEmitter<any>();
  
  private empresas: any = [];
  private lojas: any = [];
  private roles: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder, public vf: UtilizadorFormValidationService,) {
    this.createForm();
    this.formErrors = this.vf.errorMessages;
  }

  

  ngOnInit() {
    this.empresasOption();
    this.getLojas()
    this.rolesOption()
  }

  createForm() {
    this.simpleFormutilizador = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: [null, Validators.required],
      telefone: [null, Validators.required],
      morada: [null],
      empresa_id: [null, Validators.required],
      loja_id: [null],
      email: [null, Validators.required],
      role_id: [null, Validators.required],
      status: [null, Validators.required],
      username: [null, Validators.required],
      password: [null,
        [Validators.required,
        Validators.minLength(this.vf.formRegras.passwordMin),
        Validators.pattern(this.vf.formRegras.passwordPattern)
        ]],
      password_confirmation: ['',[Validators.required]],
    },{ validator: password_confirmationValidator });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormutilizador.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormutilizador.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormutilizador.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormutilizador.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'user/register' : 'user/update/' + index);
    this.createOrEdit(uri, this.simpleFormutilizador, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset(); 
        }  
        
        if (Object(response).code ==200) {
          this.loadListutilizadors(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
     
    if (this.utilizador !== undefined ) {
      
      this.title = "Editar utilizador";
      this.simpleFormutilizador.get('password').setValidators(null)
      this.simpleFormutilizador.get('username').setValidators(null)
      this.simpleFormutilizador.get('loja_id').setValidators(null)
      this.simpleFormutilizador.get('password_confirmation').setValidators(null)
      this.onReset()
      
      this.simpleFormutilizador.patchValue({
        index: this.utilizador.id,
        nome: this.utilizador.nome,
        empresa_id: this.utilizador.empresa_id,
        loja_id: this.utilizador.loja_id,
        telefone: this.utilizador.telefone,
        morada: this.utilizador.morada,
        status: this.utilizador.status,
        role_id: this.utilizador.role_id,
        email: this.utilizador.email, 
      });
       
      this.ver=false
    } else {
      this.onReset()
      this.title = "Registar utilizador";
      this.ver=true
    }
  }

  private empresasOption() {

    this.http.call_get('empresa/select-option', null).subscribe(
      response => {
        this.empresas = Object(response).data;
        // console.log(this.empresas)
      }
    );
  }

  private getLojas() {
    this.http.call_get('loja/selectBox', null).subscribe(
      response => {
        this.lojas = Object(response).data
      }
    );
  }


  private rolesOption() {

    this.http.call_get('role/select-option', null).subscribe(
      response => {
        this.roles = Object(response).data;
      }
    );
  }

  public loadListutilizadors(utilizador) { 
    this.loadListutilizador.emit(utilizador);
  }


}