// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import * as dotenv from 'dotenv'
// //const dotenv = require('dotenv')
//
// dotenv.config()

export const environment = {
  production: false,
  app_url: 'https://uac.itgest.co.ao/api/'
  //app_url: 'http://agt.unig-erp.com:8002/'
  //app_url: 'http://oec-oae-unig.itgest.pt:3330/'
  //app_url: 'http://oec-cno-unig.itgest.pt:3339/'
  //app_url: 'http://chipiquita.unig-erp.com:3301/'
  //app_url: 'http://zanecom.unig-erp.com:3302/'
  //app_url: 'http://epandu-vali.unig-erp.com:3303/'
  // app_url: 'http://cfma.unig-erp.com:3359/'
  //app_url: process.env.API_URL
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
 import 'zone.js/dist/zone-error';  // Included with Angular CLI.
