import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { UserResponse, UserData } from '../../models/userRresponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<UserData>;
  public currentUser: Observable<UserData>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<UserData>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserData {
    return this.currentUserSubject.value;
  }

  public login(username, password) {

    return this.http.post<UserResponse>(environment.app_url + 'user/authenticate', { username, password })
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        if (res.data.user && res.data.token.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.currentUserSubject.next(res.data);
        }
        console.log(res);
        return res.data.user;
      }));
  }

  public logoutUser() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  public logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  canActivateRouterLink(permission: string): boolean {
    const currentUser = this.currentUserValue;
    if (currentUser.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }
  updatePermissionsCurrentUser(){
    const currentUser = this.currentUserValue;
    currentUser.permissions.push('lklklkl');
  }

  getAuthStatus() { 
    return localStorage.getItem('currentUser') ? true : false;
  }
   
  public regster(user, headers) {
    return this.http.post(environment.app_url + 'user/register', user, headers);
  }

  

}
















/*

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs'
import { Subject } from 'rxjs';

export interface UserDetails {
  id: number
  nome: string
  email: string
  password: string
  exp: number
  iat: number
  uid: number

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  
  private headerOptions: any = null

  private _isLoggedIn: boolean = false
  private active_tfa: boolean
  private _timeSecrect: string

  authSub = new Subject<any>();

 

  //private baseUrl = 'http://bdt.itgest.pt:3333/';


  constructor(private http: HttpClient) {
  }

  public regster(user, headers) {
    return this.http.post(environment.app_url + 'user/register', user, headers);
  }

  public authenticate(credentials: any) {
    return this.http.post(environment.app_url + 'user/authenticate', credentials);
  }

  public setToken(token) {
    localStorage.setItem('userToken', token);
  }

  public getToken() {
    return localStorage.getItem('userToken');
  }

  public setTime(time) {
    localStorage.setItem('userToken', time);
  }

  public getTime() {
    return localStorage.getItem('time');
  }

  public setLoading(valor) {
    localStorage.setItem('loading', valor);
  }

  public getLoading() {
    return localStorage.getItem('loading');
  }

  isLogged() {
    return localStorage.getItem('userToken') != null;
  }


  public getUserDetails(): UserDetails {
    const token = this.getToken()
    let payload
    if (token) {
      payload = token.split('.')[1]
      payload = window.atob(payload)
      return JSON.parse(payload)
    } else {
      return null
    }
  }

  public profile(id): Observable<any> {
    return this.http.get(environment.app_url + `user/getuser/${id}`)
  }


  loginAuth(code: any, userObj: any) {

    /*let headerss: any = [];
    headerss['x-tfa'] = "";
    if (userObj.authcode) {
      console.log('Appending headers');
      this.headerOptions = new HttpHeaders({
        'x-tfa': userObj.authcode
      });
      console.log(this.headerOptions.get("x-tfa"));
      headerss['x-tfa'] = this.headerOptions.get("x-tfa")
    }*

    return this.http.post(environment.app_url + "user/authenticate", { uname: userObj.uname, upass: userObj.upass, code, tempSecret: localStorage.getItem('tempSecret') }, { observe: 'response', headers: this.headerOptions });
  }

  setupAuth() {
    return this.http.post(environment.app_url + "tfa/setup", {}, { observe: 'response' })
  }

  registerUser(userObj: any) {
    return this.http.post(environment.app_url + "register", { uname: userObj.uname, upass: userObj.upass }, { observe: "response" });
  }

  updateAuthStatus(value: boolean, tfa: boolean) {
    this._isLoggedIn = value
    this.active_tfa = tfa;

    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', value ? "true" : "false");
    localStorage.setItem('isActiveTfa', tfa ? "true" : "false");
  }

  getAuthStatus() {
    this._isLoggedIn = localStorage.getItem('isLoggedIn') == "true" ? true : false;
    return this._isLoggedIn
  }
  getTimeSecrect() {
    this._timeSecrect = localStorage.getItem('timeSecrect');
    return this._timeSecrect
  }
  getAuthTFaStatus() {
    this.active_tfa = localStorage.getItem('isActiveTfa') == "true" ? true : false;
    return this.active_tfa
  }



  logoutUser() {
    this._isLoggedIn = false;
    this.authSub.next(this._isLoggedIn);
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem('isActiveTfa')
    localStorage.removeItem('board')
  }

  public logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  getAuth() {
    return this.http.get(environment.app_url + "tfa/setup", { observe: 'response' });
  }

  deleteAuth() {
    return this.http.delete(environment.app_url + "tfa/setup", { observe: 'response' });
  }

  verifyAuth(token: any) {
    return this.http.post(environment.app_url + "tfa/verify", { token }, { observe: 'response' });
  }

}*/
