 

export interface CardInterface {
  id: string;
  header: string;
  summary: string;
  description: string;
  data: string;
  created_at: string;
}


export class Card implements CardInterface {

  constructor(public id: string, public header: string, public summary: string, public description: string, public data: string, public created_at: string) {
  }




 

}
