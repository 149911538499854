import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { map, retry, catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // variavel de filtração de dados
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5, // Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filterDocumentsFacturacao: ['FT', 'NC', 'FR', 'FS']
  };

  constructor( private auth: AuthService, private http: HttpClient ) { }

  public __call(url: string, body: object) {
    const token = this.auth.currentUserValue.token.type + ' ' + this.auth.currentUserValue.token.token;
    const headers = new HttpHeaders({'Content-Type': 'application/json', Authorization: token});
    url = environment.app_url + url;
    return this.http.post<any>(url, body, {headers});
  }
  public call_get(url: string, body: object) {
    const token = this.auth.currentUserValue.token.type + ' ' + this.auth.currentUserValue.token.token;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });
    url = environment.app_url + url;
    return this.http.get(url, { headers });
  }

  public getBaseUrl(){
    return environment.app_url;
  }
  canActivateRouterLink(permission: string): boolean {
    return this.auth.canActivateRouterLink(permission);
  }
  
  public _getFileFromServer(url): any {
    url = environment.app_url + url;
    return this.http.post<Blob>(url, null, { responseType: 'blob' as 'json' }).pipe(
      map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        })
    );
  }
}
