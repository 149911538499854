
import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'createOrEditproduto',
  templateUrl: './create-or-edit-produto.component.html',
  styleUrls: ['./create-or-edit-produto.component.css']
})
export class CreateOrEditProdutoComponent implements OnInit {

  @Input() modal: string = "modalprodutoCreateOrEdit";
  @Input() title: string = "Registar produto";
  @Input() produto:any;

  imponstos = [
    { id: 0, descricao: null }
  ]


  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormproduto: FormGroup; 

  @Output() private loadListproduto = new EventEmitter<any>();

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.getImposto();
    this.configService.listarMoedas(); 
  }

  createForm() {
    this.simpleFormproduto = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: [null, Validators.required],
      barcode: [null],
      quantidade: [null],
      tipo: [null, Validators.required],
      valor: [null, [Validators.required, Validators.min(0)]],
      imposto_id: [null, Validators.required],
      is_active: [null, Validators.required],
      moeda_id: [null, Validators.required],
      observacao: [null],
      has_sales_documents: [0],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormproduto.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormproduto.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormproduto.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormproduto.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'artigo/create' : 'artigo/update/' + index);
    this.createOrEdit(uri, this.simpleFormproduto, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code === 200) {
          formulario.reset();
          this.loadListprodutos(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListprodutos(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.produto !== undefined ) {
      this.title = "Editar produto";
      this.simpleFormproduto.patchValue({
        index: this.produto.id,
        nome: this.produto.nome,
        quantidade: this.produto.quantidade,
        barcode: this.produto.barcode,
        tipo: this.produto.tipo,
        observacao: this.produto.observacao,
        valor: this.produto.valor,
        moeda_id: this.produto.moeda_id,
        imposto_id: this.produto.imposto_id,
        is_active: this.produto.is_active,
        has_sales_documents: this.produto.has_sales_documents,
      });
    } else {
      this.title = "Registar produto";
    }
  }

  private getImposto() {

    this.http.__call('imposto/getall', null).subscribe(
      data => {
        var count = Object.keys(data).length;
        for (var i = 0; i < count; i++) {
          this.imponstos[i] = data[i];
        }
      }
    );
  }

  public loadListprodutos(produto) {
    this.loadListproduto.emit(produto);
  }
}

