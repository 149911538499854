import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ServicoComponent } from './components/servico/servico.component';
import { VendaComponent } from './components/venda/venda.component';
import { TerceiroComponent } from './components/terceiro/terceiro.component';
import { FacturacaoComponent } from './components/facturacao/facturacao.component';
import { PontoVendaComponent } from './components/ponto-venda/ponto-venda.component';
import { AvencaComponent } from './components/avenca/avenca.component';
import { ProdutoComponent } from './components/produto/produto.component';
import { ContabilidadeComponent } from './components/contabilidade/contabilidade.component';
import { CompraComponent } from './components/compra/compra.component';
import { RecursoHumanoComponent } from './components/recurso-humano/recurso-humano.component';
import { PagamentoComponent } from './components/pagamento/pagamento.component';
import { RegisterComponent } from './components/register/register.component';
//import { LoginComponent } from './components/login/login.component';
import { CriarArtigoComponent } from './components/artigo/criar-artigo/criar-artigo.component';
import { ListarArtigoComponent } from './components/artigo/listar-artigo/listar-artigo.component';
import { FornecedorComponent } from './components/fornecedor/fornecedor.component';
import { UtilizadorComponent } from './components/utilizador/utilizador.component';
import { ListarProdutosComponent } from './components/listar-produtos/listar-produtos.component';
import { ImpostoComponent } from './components/imposto/imposto.component';
import { DocumentoComponent } from './components/documento/documento.component';
import { SerieComponent } from './components/serie/serie.component';
import { ListarFacturacaoComponent } from './components/listar-facturacao/listar-facturacao.component';
import { IsentoComponent } from './components/isento/isento.component';
//import { AuthGuard } from './providers/auth/auth.guard';
import { ReciboComponent } from './components/recibo/recibo.component';
import { ConsultarReciboComponent } from './components/recibo/consultar-recibo/consultar-recibo.component';
import { NotaCreditoComponent } from './components/nota-credito/nota-credito.component';
import { SaftComponent } from './components/saft/saft.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { StockMovimentoComponent } from './components/stock-movimento/stock-movimento.component';
import { ContaCorrenteComponent } from './components/conta-corrente/conta-corrente.component';

import { PermissionsComponent } from './components/permissions/permissions.component';
import { RoleComponent } from './components/role/role.component';
import { ModulosComponent } from './components/modulos/modulos.component';
import { ArmazemComponent } from './components/armazem/armazem.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { StockComponent } from './components/stock/stock.component';


//import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/Login/login.guard';
import { OrcamentoComponent } from './components/orcamento/orcamento.component';
import { ListaOrcamentoComponent } from './components/lista-orcamento/lista-orcamento.component';

import { BiComponent } from './bi/bi.component';

import { TarefaComponent } from './components/tarefa/tarefa.component';
import { ProdutoFornecedorComponent } from './components/produto-fornecedor/produto-fornecedor.component';

import { MoedaComponent } from './components/moeda/moeda.component';
import { BancoComponent } from './components/banco/banco.component';
import { ProjectoComponent } from './components/projecto/projecto.component';

import { P404Component } from './components/error/404.component';
import { P500Component } from './components/error/500.component';
import { P403Component } from './components/error/403.component';

import { LoginComponent } from './components/autenticacao/login/login.component';
import { TarifarioComponent } from './components/tarifario/tarifario.component';
import { ContratoComponent } from './components/contrato/contrato.component';
import { FacturacaoChargeComponent } from './components/facturacao-charge/facturacao-charge.component';
import { PlanoPrecoComponent } from './components/plano-preco/plano-preco.component';

import { TestPaginationComponent } from './components/test-pagination/test-pagination.component';
import { LojaComponent } from './components/loja/loja.component';
import { PaisComponent } from './components/pais/pais.component';
import { TipoClienteComponent } from './components/terceiro/tipo-cliente/tipo-cliente.component';
import { TipoIdentidadeComponent } from './components/terceiro/tipo-identidade/tipo-identidade.component';
import { RedefinirPasswordFirstAcessComponent } from './components/utilizador/redefinir-password-first-acess/redefinir-password-first-acess.component';
import { EmitirNotaCreditoComponent } from './components/facturacao/emitir-nota-credito/emitir-nota-credito.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '403',
    component: P403Component,
    data: {
      title: 'Erro 403'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  { path: 'test', component: TestPaginationComponent },
  {
    path: 'password-first-access',
    component: RedefinirPasswordFirstAcessComponent,
  },
  {
    path: '',
    children: [
      {
        path: 'listarFacturacao',
        component: ListarFacturacaoComponent,canActivate: [AuthGuard], data: { expectedPermission: 'listar_facturacao' }
      },
      { path: 'facturacao/emitir-nota-de-credito/:id', component: EmitirNotaCreditoComponent, data: { expectedPermission: 'nota_credito_factura' } },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },


      {
        path: 'vendas',
        children: [
          {
            path: 'facturacao',
            canActivate: [AuthGuard],
            children: [
              { path: 'listar-facturacao', component: ListarFacturacaoComponent,canActivate: [AuthGuard], data: { expectedPermission: 'listar_facturacao' } },
              { path: 'pdv-ponto-venda', component: PontoVendaComponent, canActivate: [AuthGuard] }, 
            ]
          },
          {
            path: 'recibo',
            canActivate: [AuthGuard],
            children: [
              { path: 'gera-recibo', component: ReciboComponent, canActivate: [AuthGuard], data: { expectedPermission: 'gerar_recibos' } },
              { path: 'listar-recibos', component: ConsultarReciboComponent, canActivate: [AuthGuard], data: { expectedPermission: 'gerar_recibos' } },
               
            ]
          }
        ]
      },
 


      { path: 'servicos', component: ServicoComponent, canActivate: [AuthGuard] },
      { path: 'vendas', component: VendaComponent, canActivate: [AuthGuard] },
      { path: 'clientes', component: TerceiroComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_clientes' } },
      { path: 'facturacao', component: FacturacaoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'registar_facturacao' } },
      { path: 'pontosVenda', component: PontoVendaComponent, canActivate: [AuthGuard] },
      { path: 'produtos', component: ProdutoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_produtos' } },
      { path: 'contabilidade', component: ContabilidadeComponent, canActivate: [AuthGuard] },
      { path: 'recursosHumanos', component: RecursoHumanoComponent, canActivate: [AuthGuard] },
      { path: 'compras', component: CompraComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_compras' } },
      { path: 'pagamentos', component: PagamentoComponent, canActivate: [AuthGuard] },
      { path: 'artigo/criar', component: CriarArtigoComponent, canActivate: [AuthGuard] },
      { path: 'artigo/listar', component: ListarArtigoComponent, canActivate: [AuthGuard] },
      { path: 'utilizadores', component: UtilizadorComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_utilizadores' }},
      { path: 'fornecedores', component: FornecedorComponent, canActivate: [AuthGuard]},
      { path: 'listarProdutos', component: ListarProdutosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_produtos' } },
      { path: 'impostos', component: ImpostoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_impostos' } },
      { path: 'isentos', component: IsentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_impostos' } },
      { path: 'documentos', component: DocumentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_documentos'} },
      { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'configurar_empresa'} },
      { path: 'series', component: SerieComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_series' } },
      { path: 'tarifarios', component: TarifarioComponent, canActivate: [AuthGuard], data: {   } },
      { path: 'contratos', component: ContratoComponent, canActivate: [AuthGuard], data: {   } },
      { path: 'planoPrecos', component: PlanoPrecoComponent, canActivate: [AuthGuard], data: {   } },
      { path: 'facturacao-charge', component: FacturacaoChargeComponent, canActivate: [AuthGuard], data: {  } },

      { path: 'saft', component: SaftComponent, canActivate: [AuthGuard], data: { expectedPermission: 'gerar_saft' } },
      { path: 'configurarPais', component: PaisComponent, canActivate: [AuthGuard], data: { expectedPermission: 'configurar_pais' } },
      { path: 'stockMovimento', component: StockMovimentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'stock_movimento' } },
      { path: 'contaCorrente', component: ContaCorrenteComponent, canActivate: [AuthGuard], data: { expectedPermission: 'conta_corrente' }}, 
      { path: 'permissions', component: PermissionsComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_permissions' } },
      { path: 'roles', component: RoleComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_roles' } },
      { path: 'modulos', component: ModulosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_modulos' } },
      { path: 'stock', component: StockComponent, canActivate: [AuthGuard] },
      { path: 'inventariacao', component: InventarioComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_inventarios' } },
      { path: 'armazem', component: ArmazemComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_armazens' } },
      { path: 'orcamento', component: OrcamentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_orcamentos' } },
      { path: 'listarOrcamento', component: ListaOrcamentoComponent },
      { path: 'bi', component: BiComponent, canActivate: [AuthGuard], data: { expectedPermission: 'business_intelligence' } },
      { path: 'tarefa', component: TarefaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'tarefas' } },
      { path: 'produtos-fornecedores', component: ProdutoFornecedorComponent, canActivate: [AuthGuard] },
      { path: 'moedas', component: MoedaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_moedas' } },
      { path: 'bancos', component: BancoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_bancos' } },
      { path: 'projectos', component: ProjectoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_projectos' } },
      { path: 'tipoCliente', component: TipoClienteComponent, canActivate: [AuthGuard], data: { expectedPermission: 'tipo_cliente' } },
      { path: 'tipoIdentidade', component: TipoIdentidadeComponent, canActivate: [AuthGuard], data: { expectedPermission: 'tipo_identidade' } },
      { path: 'lojas', component: LojaComponent, canActivate: [AuthGuard], data: { } }


    ]
  },
  /*
    { path: "", redirectTo: '/login', pathMatch: 'full', canActivate: [LoginGuard] },
    { path: "login", component: LoginComponent /*, canActivate: [LoginGuard] *},
    //{ path: "two-tfa-auth", component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: "register", component: RegisterComponent, canActivate: [LoginGuard] },*/
  { path: '**', component: P404Component },
  


];




@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule,
    ReactiveFormsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routeComponents = [
  LoginComponent,
  DashboardComponent,
  ServicoComponent,
  VendaComponent,
  TerceiroComponent,
  FacturacaoComponent,
  PontoVendaComponent,
  AvencaComponent,
  ProdutoComponent,
  PagamentoComponent,
  ContabilidadeComponent,
  CompraComponent,
  RecursoHumanoComponent,
  RegisterComponent,
  CriarArtigoComponent,
  ListarArtigoComponent,
  FornecedorComponent,
  UtilizadorComponent,
  ListarProdutosComponent,
  ImpostoComponent,
  DocumentoComponent,
  SerieComponent,
  ListarFacturacaoComponent,
  ReciboComponent,
  NotaCreditoComponent,
  StockMovimentoComponent,
  ContaCorrenteComponent,
  PermissionsComponent,
  RoleComponent,
  ModulosComponent,
  InventarioComponent,
  StockComponent,
  ArmazemComponent,
  OrcamentoComponent,
  ListaOrcamentoComponent,
  BiComponent,
  TarefaComponent,
  ProdutoFornecedorComponent
]
