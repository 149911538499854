import { Component, OnInit,ViewChild } from '@angular/core'; 
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ReportProdutosService } from 'src/app/components/Relatorios/report-produtos.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-listar-produtos',
  templateUrl: './listar-produtos.component.html',
  styleUrls: ['./listar-produtos.component.css']
})

export class ListarProdutosComponent implements OnInit {

  constructor(private http: HttpService,private configService: ConfigService, private excelService: ExcelService, private ReportProdutos:ReportProdutosService) { }
  
  public produto: any;
  private loading: boolean = false;
   

 
  private items:any = [];
  private dados_empresa:any = [];
  ngOnInit() { 
    this.getPageFilterData(1);
    this.user_empresa()
  }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Produtos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.ReportProdutos.relatorioProdutos(this.items,'save',this.dados_empresa,file)
  }

  imprimirPDF(): void {
    var file =  document.getElementsByClassName("exportPDFCliente")[0]
    this.ReportProdutos.relatorioProdutos(this.items,'imprimir',this.dados_empresa,file)
  }

/**
 * 
 * @param start 
 * @param end 
 * @param search 
 */
  private listarProdutos() {  
    this.loading = true
    this.http.__call('artigo/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarProdutos();
  } 

  
  private setDataProduto(produto) {
    this.produto = produto
  }

  public user_empresa() {
    this.http.call_get('empresa/empresa-user/',null).subscribe(
      response => {
        this.dados_empresa = Object(response).data;
        console.log(this.dados_empresa)
      }
    );
  }
 
}