import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footernavbar',
  templateUrl: './footernavbar.component.html',
  styleUrls: ['./footernavbar.component.css']
})
export class FooternavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
