import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-loja',
  templateUrl: './loja.component.html',
  styleUrls: ['./loja.component.css']
})
export class LojaComponent implements OnInit {

  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  private lojas: any = [];
  private provincias: any = [];
  private municipios: any = [];
  private title_modal: string;

  submitted = false; 
  simpleForm: FormGroup; 

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    this.createForm();
  }

  ngOnInit() {
    this.getPageFilterData(1);

  } 

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = page;
    this.listarLojas();
  }

  /**
   * @name "Listar lojas"
   * @descriptio "Esta Função permite Listar todas lojas"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarLojas() { 
    this.configService.loaddinStarter('start');
    this.http.__call('loja/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.lojas = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  titleModal(title: string){
    this.title_modal = title;
    this.getProvincias();
  }

  createForm(){
    this.simpleForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: ['', Validators.required],
      numero: ['', Validators.required],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      endereco: ['', Validators.required],
      is_active: ['', Validators.required],
      provincia_id: ['', Validators.required],
      municipio_id: ['', Validators.required], 
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

   onReset() {

    this.submitted = false;
    this.simpleForm.reset();

  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }

    this.configService.loaddinStarter('start');

    const index = this.simpleForm.getRawValue().index;
    if (index === null) {
      // TODO: usado para fazer a requisição com a api de criação de organismo
      const uri = 'loja/create';
      this.createOrEdit(uri, this.simpleForm, true);
    } else {
      // TODO: update
      const uri = 'loja/update/' + index;
      this.createOrEdit(uri, this.simpleForm, false);
    }
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value) .subscribe(
        res => {
          this.submitted = false; 
          if (isCreate) {
            simpleFormulario.reset();
          }else{

          }
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.listarLojas();
        }/*,
        error => {
          this.submitted = false; 
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }*/
        );
    this.configService.loaddinStarter('stop');
  }


  private editar(loja:any) { 
    
    this.simpleForm.patchValue({
      index: loja.id,
      nome: loja.nome,
      numero: loja.numero,
      telefone: loja.telefone,
      email: loja.email,
      endereco: loja.endereco,
      is_active: loja.is_active,
      provincia_id: loja.provincia_id
    });
this.getMunicipios();
    this.simpleForm.patchValue({ 
      municipio_id: loja.municipio_id, 
    });
  }

   private getProvincias() { 
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {     
        this.provincias = Object(response).data
      }
    );
  }
  private getMunicipios() { 
    const id = this.simpleForm.getRawValue().provincia_id; 
    this.http.call_get('municipio/selectBox/'+id, null).subscribe(
      response => {     
        this.municipios = Object(response).data[0].municipios 
      }
    );
  }

}
