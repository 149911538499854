
import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TerceiroComponent } from 'src/app/components/terceiro/terceiro.component';
import _ from 'lodash';


export const tinValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const tin = control.get('contribuente');
  const passiveCustomer = control.get('passive_customer');
  return passiveCustomer.value == 1 && _.isNil(tin.value) ? { 'nifRequired' : true} : null
};


@Component({
  selector: 'createOrEditClient',
  templateUrl: './create-or-edit-client.component.html',
  styleUrls: ['./create-or-edit-client.component.css']
})
export class CreateOrEditClientComponent implements OnInit, OnChanges {

  @Input() modalClienteCreateOrEdit: string = "modalClienteCreateOrEdit";
  @Input() title: string = "Registar Cliente";
  @Input() cliente: any;
 

  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormCliente: FormGroup;
  @Input() selectForms: any; 

  @Output() private loadListClient = new EventEmitter<any>();
  municipios: any = [];
  provincias:any=[];

  private process:any={
    provincia:null,
    municipio:null
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder, private TerceiroComponent:TerceiroComponent) {
    this.createForm();
  }

  ngOnInit() { 
  }

  createForm() {
    this.simpleFormCliente = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: [null, Validators.required],
      contribuente: [null],
      email: [null, Validators.required],
      tipo_identidade_id: [2, Validators.required],
      tipo_cliente_id: [null, Validators.required],
      telefone: [null, Validators.required],
      morada: [null, Validators.required],
      observacao: [null],
      pais: [null, Validators.required],
      province: [null, Validators.required],
      city: [null, Validators.required],
      zip_code: [null, Validators.required],
      passive_customer: [0, Validators.required],
      has_sales_documents: [0],
    },{ validator: tinValidator });
  }
 
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCliente.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCliente.reset();
  }


  onSubmit() {

    this.submitted = true;
    // parar aquei se o formulário for inválido
    if (this.simpleFormCliente.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCliente.getRawValue().index;

    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'cliente/register' : 'cliente/update/' + index);
    this.createOrEdit(uri, this.simpleFormCliente, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset(); 
        }
        if (Object(response).code ==200) { 
          this.loadListClients(Object(response).data);

        }         
        this.configService.showAlert(Object(response).message, "alert alert-success", true);
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }




  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {


    if (this.cliente !== undefined ) {
      this.getProvincias();
      this.getMunicipios();
      this.title = "Editar Cliente";
      this.simpleFormCliente.patchValue({
        index: this.cliente.id,
        nome: this.cliente.nome,
        contribuente: this.cliente.contribuente,
        email: this.cliente.email,
        tipo_identidade_id: this.cliente.tipo_identidade_id,
        tipo_cliente_id: this.cliente.tipo_cliente_id,
        genero: this.cliente.genero,
        telefone: this.cliente.telefone,
        province: this.cliente.province,
        morada: this.cliente.morada,
        city: this.cliente.city,
        pais:  this.cliente.pais,
        observacao: this.cliente.observacao,
        zip_code: this.cliente.zip_code,
        passive_customer: this.cliente.passive_customer,
        has_sales_documents: this.cliente.has_sales_documents,
      });
    } else {
      this.title = "Registar Cliente";
    }
  }

  private getMunicipios() {
    this.process.municipio = "Carregando...";
    this.http.call_get('select-option/municipioByProvincia/' + this.simpleFormCliente.getRawValue().province, null).subscribe( 
      response => { 
        this.municipios = Object(response).data; 
        this.process.municipio = "Selecione o concelho";
      }
    );
  }
  
  private getProvincias() {
    this.process.provincia = "Carregando...";
    this.http.call_get('select-option/provinciasByPais/' + this.simpleFormCliente.getRawValue().pais, null).subscribe(
      response => {
        this.provincias = Object(response).data
        this.process.provincia = "Selecione o distrito";
      }
    );
  }

  public loadListClients(client) { 
    this.loadListClient.emit(client);
  }


}
