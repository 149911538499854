import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import _ from 'lodash';

@Component({
  selector: 'app-emitir-nota-credito',
  templateUrl: './emitir-nota-credito.component.html',
  styleUrls: ['./emitir-nota-credito.component.css']
})
export class EmitirNotaCreditoComponent implements OnInit {

  private factura_id: number;
  private factura: any = null;
  private loading: boolean = false;

  private sucesso: boolean = false;
  private line : any;
  private series: any = []
  private serie: number = null;
  private observacao: string;
  private motivo: string;
  total: number = 0;
  totalComImposto: number = 0;
  totalSemImposto: number = 0;
  private imprimir:any; 

  private item: any = {
    id: null,
    quantidade: null,
    linha_id:null
  }

  constructor(private http: HttpService, private configService: ConfigService, private route: ActivatedRoute, private _route: Router) {
    this.route.paramMap.subscribe(params => {
      this.factura_id = +params.get('id');
    });

  }

  ngOnInit() {
    this.getFactura(this.factura_id);
    this.getSeries()
  }

  ngOnDestroy() { }

  private getFactura(factura_id) {
    var tipo = 'NC'
    this.loading = true
    this.http.__call('factura/gerarFactura/' + factura_id, {tipo:tipo}).subscribe(
      response => {
        if (Object(response).code === 500) {
          this._route.navigate(['/403']);
        } else {
          if (Object(response).data.factura.status === 'N' && (Object(response).data.factura.sigla === 'FT' || Object(response).data.factura.sigla === 'FS' || Object(response).data.factura.sigla === 'FR')) {
            this.factura = Object(response).data; 
            this.countOnCaseOfOnlyOneLine(false)
            this.factura.produtos.forEach(element => { Object.assign(element, { checked: false }); });
          } else {
            this._route.navigate(['/403']);
          }
        }
        this.loading = false;

      }
    );
  }

  /**
  * @name "Listar series"
  * @descriptio "Esta Função permite Listar todas series"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  private getSeries() {
    this.loading = true

    this.series = []
    this.http.__call('serie/selectBoxSeries', { documentos: ["NC"] }).subscribe(
      response => {
        this.series = Object(response).data;
        this.loading = false;
      }
    );

  }


  setProduto(p: any) {
    this.line = p;
    this.item.id = p.produto_id;
    this.item.linha_id = p.id;
    this.item.quantidade = p.quantidade;
  }

  getCalcularLine() {
    if (this.item.quantidade <= 0 || isNaN(this.item.quantidade) || this.item.quantidade > this.line.qtd_fixo) {
      this.configService.showAlert("Não foi informada uma quantidade válida", "alert-danger", true);
      return;
    }

    for (let index = 0; index < this.factura.produtos.length; index++) {
      const l = this.factura.produtos[index];
      if (l.id === this.item.linha_id) {
        if(l.globalLineDiscount != null && l.globalLineDiscount != 0) {

          l.globalLineDiscount = parseFloat(((l.globalLineDiscount * this.item.quantidade) / l.quantidade).toFixed(3))
        }

        l.discount_amount = parseFloat(((l.discount_amount * this.item.quantidade) / l.quantidade).toFixed(3))

        l.quantidade = this.item.quantidade;


        // l.valor_desconto = l.valor_desconto == 0 ? 0 : parseFloat((l.valor * ((l.valor / l.valor_desconto) / 100)).toFixed(3));

        var desc = (l.valor * this.item.quantidade)  - l.discount_amount - l.globalLineDiscount;

        l.valorImposto = parseFloat((desc * (l.vImposto / 100)).toFixed(3));

        l.linhaTotalSemImposto = parseFloat((desc).toFixed(3));

        l.total = l.linhaTotalSemImposto + l.valorImposto;

        this.factura.produtos.splice(index, 1);
        this.factura.produtos.splice(index, 0, l);

      }
    }
    this.calcularTotal();
  }


  private finalizarNotaCredito() {
    this.validationLineCheckbox();
     
    this.configService.loaddinStarter('start');
    if (this.serie == undefined) {
      this.configService.showAlert('É obrigatório fornecer uma serie', "alert-danger", true);
      return;
    } 
    else if (this.motivo == null) {
      this.configService.showAlert('É obrigatório informar o motivo da Nota de Crédito', "alert-danger", true);
      return;
    }else if (this.validation === 0) {
      this.configService.showAlert('É obrigatório selecionar pelo menos uma linha da factura', "alert-danger", true);
      return;
    } else {

      var produts = [];
      for (let index = 0; index < this.factura.produtos.length; index++) {
        const l = this.factura.produtos[index];
        if (l.checked === true) {
          produts.push(l);
        }
      }
      this.loading = true;
      this.http.__call('factura/create', {
        produtos: produts,
        documento: 'Nota de Crédito',
        cliente: this.factura.cliente.id,
        total: this.total,
        serie_id: this.serie,
        totalComImposto: this.totalComImposto,
        totalSemImposto: this.totalSemImposto,
        observacao: this.observacao,
        motivo: this.motivo,
        globalDiscountPercentage: this.factura.factura.global_discount_pct,
        numero_origem_factura: this.factura.factura.factura_sigla,
        data_origem_factura: this.factura.factura.created_at,
        moeda: null
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.sucesso = true;
            this.imprimir = Object(res).data.id
            this.configService.showAlert(Object(res).message, "alert-success", true); 
          }
          this.loading = false;
        }
      );

    }
    this.configService.loaddinStarter('stop');
  }

  private countOnCaseOfOnlyOneLine(dontRun){
    if(this.factura.produtos.length == 1){
      const l = this.factura.produtos[0];
      if(l.quantidade == 1) {
        if(l.globalLineDiscount != null && l.globalLineDiscount != 0 && dontRun == false) {

          l.globalLineDiscount = parseFloat(((l.globalLineDiscount * l.quantidade) / l.originalQuantity).toFixed(3))
        }

        l.discount_amount = parseFloat((l.desconto).toFixed(3))



        // l.valor_desconto = l.valor_desconto == 0 ? 0 : parseFloat((l.valor * ((l.valor / l.valor_desconto) / 100)).toFixed(3));

        var desc = (l.valor * l.quantidade)  - l.discount_amount - l.globalLineDiscount;

        l.valorImposto = parseFloat((desc * (l.vImposto / 100)).toFixed(3));

        l.linhaTotalSemImposto = parseFloat((desc).toFixed(3));

        l.total = l.linhaTotalSemImposto + l.valorImposto;

        this.factura.produtos.splice(0, 1);
        this.factura.produtos.splice(0, 0, l);
      }
    }
  } 

  private calcularTotal() {
    this.total = 0;
    this.totalSemImposto = 0;
    this.totalComImposto = 0;

    this.countOnCaseOfOnlyOneLine(true)
    

    //Calcula o Total da Factura
    for (let index = 0; index < this.factura.produtos.length; index++) {
      if (this.factura.produtos[index].checked === true) {
        this.totalSemImposto += this.factura.produtos[index].linhaTotalSemImposto;
        this.totalComImposto += this.factura.produtos[index].valorImposto;
        this.total += this.factura.produtos[index].total;
      }
    }

  }

  private linesChecked: any = [];

  setLineCheckbox(produt: any) { 
    this.factura.produtos.forEach(element => {
      if (element.id === produt.id) {
        if (element.checked == false) {
          Object.assign(element, { checked: true });
        } else {
          Object.assign(element, { checked: false });
        }
      }
    });
    this.calcularTotal();
  }

  validation: number = 0;

  validationLineCheckbox() {
    this.validation = 0;
    this.factura.produtos.forEach(element => {
      if (element.checked === true) {
        this.validation++;
      }
    });
  }

  back(){
    this._route.navigate(['/listarFacturacao']);
  }


}

